import { FC, useEffect, useState } from "react";
import { createTeamInitialValues } from "../../utils/CreateTeamValues";
import { CreateTeamTypes } from "../../types/CreateTeamTypes";
import { CreateTeamHeader } from "./CreateTeamHeader";
import { CreateTeamFooter } from "./CreateTeamFooter";
import { CreateTeamStep1 } from "./pages/CreateTeamStep1";
import { CreateTeamStep2 } from "./pages/CreateTeamStep2";
import {
  cleanEntityName,
  convertCleanEntityNameToSlug,
  normalizeString,
} from "@/utils/constants/stringProcessing";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createOrUpdateTeams } from "../../helperFunctions/createOrUpdateTeam";
import { createToast } from "@/utils/toaster/createToast";
import { validateTeamForm } from "../../utils/ValidateTeamForm";
import { TeamCardComponent } from "../teamCard/TeamCardComponent";
import placeholderImage from "../../../../assets/placeholders/placeholder.png";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { useAtom } from "jotai";
import { teamDraftAtom } from "@/utils/atoms/draftsAtoms/draftsAtoms";
import { CreateTeamDraftModal } from "./CreateTeamDraftModal";

export const CreateTeamForm: FC<{
  isEdit: boolean;
  team?: any;
}> = ({ isEdit, team }) => {
  const [teamValues, setTeamValues] = useState<CreateTeamTypes>(
    createTeamInitialValues
  );

  // Current step
  const [step, setStep] = useState<number>(0);
  const [errors, setErrors] = useState<any>();
  const [submitting, setIsSubmitting] = useState<boolean>(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [imgSrc, setImgSrc] = useState<string | undefined>(undefined); // TEMP
  const [teamDraft, setTeamDraft] = useAtom(teamDraftAtom);
  const [draftLoaded, setDraftLoaded] = useState<boolean>(false);
  const [draftModalOpen, setDraftModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const setFieldValue = async (targetName: string, value: any) => {
    const doc = {
      ...teamValues,
      [targetName]: value,
    };
    setTeamValues(doc);

    if (!isEdit) {
      setTeamDraft(JSON.stringify(doc));
      if (!teamDraft) {
        createToast({
          type: "info",
          message: "Team draft created",
        });
      }
    }
  };

  useEffect(() => {
    if (draftLoaded) {
      return setDraftLoaded(true);
    }

    if (!draftLoaded && teamDraft) {
      setDraftLoaded(true);
      setDraftModalOpen(true);
    }
  }, [teamDraft]);

  const handleSetDraft = () => {
    setTeamValues(JSON.parse(teamDraft));
  };
  // Steps/pages
  const steps = [
    <CreateTeamStep1
      setFieldValue={setFieldValue}
      teamValues={teamValues}
      errors={errors}
      isEdit={false}
    />,
    <CreateTeamStep2 teamValues={teamValues} />,
  ];

  const handleNextStep = async () => {
    const hasErrors = await validateTeamForm(teamValues, step);
    setErrors(hasErrors);

    if (!hasErrors) {
      if (step + 1 === steps.length) {
        if (!currentUser) {
          return false;
        }
        setIsSubmitting(true);

        // if isEdit only update certain fields
        const slug = encodeURIComponent(
          normalizeString(
            convertCleanEntityNameToSlug(cleanEntityName(teamValues.name))
          )
        );
        await createOrUpdateTeams(teamValues, slug, currentUser, isEdit)
          .then(() => {
            createToast({
              type: "success",
              message: `Team \`${teamValues.name}\` has been ${
                isEdit ? "updated" : "created!"
              }`,
            });
            navigate(`${siteRoutes["teamsAndSlug"]}${slug}`);
          })
          .catch(() => {
            createToast({
              type: "error",
              message: `Something went wrong trying to ${
                isEdit ? "update" : "create"
              } your team.`,
            });
            navigate(siteRoutes["teams"]);
            setTeamDraft("");
            localStorage.removeItem("team_draft");
          });
      }

      return setStep(step + 1);
    }
    return false;
  };

  //   TEMP
  useEffect(() => {
    // create the preview
    if (teamValues.logoUrl) {
      const objectUrl = URL.createObjectURL(teamValues.logoUrl);
      setImgSrc(objectUrl);
      // free memory when ever this component is unmounted
      return () => URL.revokeObjectURL(objectUrl);
    }
    return () => setImgSrc(undefined);
  }, [teamValues.logoUrl]);

  // Conditions
  const isNotPreviewPage = step + 1 !== steps.length;

  useDocumentTitle(`Create a new team`);

  return (
    <>
      {draftModalOpen && teamDraft && (
        <CreateTeamDraftModal
          draftModalOpen={draftModalOpen}
          setDraftModalOpen={setDraftModalOpen}
          handleSetDraft={handleSetDraft}
        />
      )}

      <div className="create-team">
        {/* Header */}
        <div className="create-team__header">
          <CreateTeamHeader
            step={step}
            setStep={setStep}
            submitting={submitting}
            handleNextStep={handleNextStep}
            stepsAmount={steps.length}
            name={teamValues.name}
          />
        </div>
        {/* Page content */}
        <div
          className={`${
            isNotPreviewPage
              ? "create-team__content"
              : "create-team__content  create-team__content--preview"
          }`}
        >
          {steps[step]}
          {isNotPreviewPage ? (
            <div className="content__preview">
              <h2>Preview</h2>
              <div>
                <TeamCardComponent
                  teamData={{
                    teamId: "",
                    isFavorite: false,
                    name: teamValues?.name || "Team name",
                    slug: teamValues?.name || "",
                    games: teamValues?.games || createTeamInitialValues?.games,
                    teamLogo: imgSrc || placeholderImage,
                    firstFourParticipantsLogos: [],
                    memberCountCalculated: 1,
                    id: undefined,
                    type: teamValues.type,
                  }}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Footer */}
        <CreateTeamFooter
          step={step}
          setStep={setStep}
          submitting={submitting}
          handleNextStep={handleNextStep}
          stepsAmount={steps.length}
        />
      </div>
    </>
  );
};
