import React, { useEffect, FC } from "react";
import SingleChallengeGeneralInfo from "./SingleChallengeGeneralInfo";
import { singleChallengeProps } from "../../utils/singleChallengeProps";
import SingleChallengeAdditionalInfoWithTooltip from "./SingleChallengeAdditionalInfoWithTooltip";
import SingleChallengeFormula from "./SingleChallengeFormula";
import { SingleChallengeActionButtons } from "./SingleChallengeActionsButtons";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import CreatorCard from "@/components/CreatorCard";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import ChallengeTerms from "./ChallengeTerms";
import ChallengeDisclaimer from "./ChallengeDisclaimer";
import { partnerRoute } from "../../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/partnerRoutes";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useAtom, useSetAtom } from "jotai";

export const SingleChallengeUi: FC<{
  data: singleChallengeProps;
  isPreview: boolean;
  isPrivateForUser: boolean;
  additionalFields: {
    title: string;
    value: string;
    toolTipKey: string;
  }[];
  isEdit: boolean;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}> = ({
  data,
  isPreview,
  isPrivateForUser,
  additionalFields,
  isEdit,
  setIsEdit,
  children,
}): JSX.Element => {
  useEffect(() => {
    if (!data.isCreatedByCurrUser && setIsEdit !== undefined) {
      setIsEdit(false);
    }
  }, [isEdit]);
  // const setCurrentClient = useSetAtom(clientAtom)
  const [currentClient, setCurrentClient] = useAtom(clientAtom);

  // Page Document Title
  useDocumentTitle(`${data.challengeData.name} - Challenge`);

  // Variables
  const ChallengeDescription = data.challengeData.description;
  const ChallengeCreator = data.creatorProfile;
  const ChallengeGame = data.challengeData.gameType;
  const isEventSite = brand.event.isEvent;
  const client = brand.name;
  const ChallengeClient = data.challengeData.client;

  // Set different themes for individual partner pages
  useEffect(() => {
    if (brand.brandTheme === "elgiganten") {
      const partnerKeys = Object.keys(partnerRoute);

      partnerKeys.forEach((key) => {
        const partner = partnerRoute[key].partner;

        if (ChallengeClient === partner) {
          document.documentElement.className = partnerRoute[key].theme;
          setCurrentClient(partner);
        }
      });
    }
  }, [brand.brandTheme, partnerRoute, data]);

  return (
    <div className="challenge">
      {/* General info (Image, time, desc, tags, title) */}
      <div className="challenge__info">
        <SingleChallengeGeneralInfo
          data={data}
          isEdit={false}
          isPreview={isPreview}
          isPrivateForUser={isPrivateForUser}
        />
      </div>

      {/* Buttons (Edit, Fav, Invite) */}
      <div className="challenge__icons">
        {!isPreview && !isPrivateForUser ? (
          <SingleChallengeActionButtons
            data={data}
            setIsEdit={setIsEdit}
            isPreview={isPreview}
          />
        ) : (
          ""
        )}
      </div>

      {/* Additional info fields */}
      <div className="challenge__additional">
        {!isPrivateForUser &&
          additionalFields
            .filter((obj) => obj.value !== "")
            .map((additionalField) => {
              if (additionalField.value) {
                return additionalField.title !== "Team size" &&
                  data.challengeData.game !== "PUBG" ? (
                  <SingleChallengeAdditionalInfoWithTooltip
                    title={additionalField.title}
                    value={additionalField.value}
                    key={additionalField.title}
                    tooltip={additionalField.toolTipKey || null}
                  />
                ) : (
                  data.challengeData.game === "PUBG" && (
                    <SingleChallengeAdditionalInfoWithTooltip
                      title={additionalField.title}
                      value={additionalField.value}
                      key={additionalField.title}
                      tooltip={additionalField.toolTipKey || null}
                    />
                  )
                );
              }
            })}
      </div>

      {/* Formula */}
      {!isPrivateForUser && (
        <SingleChallengeFormula
          coefficients={data.challengeAdditionalData.fieldToCompareCoefficients}
        />
      )}

      {/* Author card */}
      {!isEventSite && (
        <div className="challenge__creator">
          {ChallengeCreator && (
            <CreatorCard
              creatorProfile={ChallengeCreator}
              isFollowedByCurrentUser={data.isCreatorFollowedByCurrentUser}
              creatorFollowersNumber={data.creatorFollowersCount}
              isPlaceholder={false}
            />
          )}
        </div>
      )}

      {/* Description */}
      {ChallengeDescription && !isEventSite && (
        <div className="challenge__description">
          <h3>Description</h3>
          <p>{ChallengeDescription}</p>
        </div>
      )}

      {isEventSite && (
        <>
          <ChallengeTerms client={currentClient} />
        </>
      )}

      {/*  Leaderboard */}
      {children}

      <ChallengeDisclaimer game={ChallengeGame} />
    </div>
  );
};
