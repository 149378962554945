import { useState, useEffect, useMemo } from "react";
import useCloudFunctionCallForListsAndInfiniteScroll from "@/hooks/useCloudFunctionCallForLists";
import {
  ChallengeOverviewProps,
  ChallengeOverviewResponse,
} from "../../types/cloudFunctions/ChallengeOverview";
import { useAuthPersistState } from "@/store/auth/auth.store";
import { ChallengeCard } from "../challengeCard";
import {
  selectedCreatorTypeProps,
  selectedPlatformProps,
} from "../../types/props/OverviewProps";
import { ChallengeOverviewUi } from "./ChallengeOverview.ui";
import ChallengeOverviwLoader from "@/components/skeletonLoaders/challengeOverviewLoader/challengeLoader";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Button } from "@/components";
import { useNavigate } from "react-router-dom";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import useCloudFunctionCallForListsAndInfiniteScrollMultiClients from "@/hooks/useCloudFunctionCallForListsMultiClients";
import { useAtom, useAtomValue } from "jotai";
import {
  challengesMineAtom,
  challengesNewAtom,
} from "@/utils/atoms/challengesAtoms/challengesAtom";
import { useDiscoverChallengesData } from "../../hooks/useDiscoverChallengesData";
import { useInView } from "react-intersection-observer";
import { useChallenges } from "../../hooks/useChallengesAlternate";
import { useChallengesv2 } from "../../hooks/useChallenges";

export const ChallengeOverview = () => {
  const currentUser = useAuthPersistState((s) => s.user);
  const challengesNewAtomData = useAtomValue(challengesNewAtom);
  const challengesMineAtomData = useAtomValue(challengesMineAtom);
  const navigate = useNavigate();

  const [selectedPlatform, setSelectedPlatform] =
    useState<selectedPlatformProps>(undefined);

  const [selectedCreatorType, setSelectedCreatorType] =
    useState<selectedCreatorTypeProps>("discover-new");

  const [selectedGame, setSelectedGames] = useState<string | undefined>(
    undefined
  );

  const [selectedStatus, setSelectedStatus] = useState<
    "upcoming" | "ongoing" | "finished"
  >("ongoing");

  const [challengeTitle, setChallengeTitle] = useState<string>("");

  const { ref, inView } = useInView({
    threshold: 0.25,
  });

  const filters = {
    status: selectedStatus,
    game: selectedGame || "",
    createdBy: selectedCreatorType,
  };

  const { challengeData, isLoading, loadMoreChallenges, fetchNew, hasMore } =
    useChallengesv2({
      filters: filters,
    });

  const atomChallenges = {
    "discover-new": challengesNewAtomData,
    mine: challengesMineAtomData,
  };

  const challengesToRender = atomChallenges[selectedCreatorType];

  useEffect(() => {
    if (!isLoading && challengesToRender) {
    }
  }, [challengesToRender]);

  useEffect(() => {
    if (selectedCreatorType) {
      fetchNew();
    }
  }, [selectedCreatorType, selectedGame, selectedStatus, selectedPlatform]);

  useEffect(() => {
    if (!isLoading && inView && hasMore) {
      fetchMore();
    }
  }, [inView]);

  // call this function when inView is true
  const fetchMore = async () => {
    loadMoreChallenges();
  };

  const inViewRefElementIndex = useMemo(() => {
    return challengesToRender ? challengesToRender?.length - 4 : -1;
  }, [challengesToRender]);

  // TO DO
  // error component for error fall back

  // Page Document Title
  if (selectedCreatorType === "mine") {
    useDocumentTitle("My challenges");
  }

  if (selectedCreatorType === "discover-new") {
    useDocumentTitle("Discover new challenges");
  }

  return (
    <>
      <ChallengeOverviewUi
        selectedCreatorType={selectedCreatorType}
        setSelectedCreatorType={setSelectedCreatorType}
        loading={isLoading}
        selectedGame={selectedGame}
        setSelectedGames={setSelectedGames}
        challengeTitle={challengeTitle}
        setChallengeTitle={setChallengeTitle}
        selectedPlatform={selectedPlatform}
        setSelectedPlatform={setSelectedPlatform}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
      />
      {selectedCreatorType === "mine" &&
        !isLoading &&
        challengesToRender?.length === 0 && (
          <div className="challenges__empty">
            <p>
              You haven't made any challenges yet.{" "}
              <span
                onClick={() => {
                  navigate(siteRoutes["createChallenge"]);
                }}
              >
                Create a new challenge
              </span>
            </p>
          </div>
        )}
      <div className="challenges__list" key={selectedCreatorType}>
        {challengesToRender &&
          challengesToRender.map((challenge, ind) => {
            const key = challenge.slug;

            return (
              <div key={key} ref={ind === inViewRefElementIndex ? ref : null}>
                {/* Render challenge card */}
                <ChallengeCard
                  created={challenge.created}
                  createdBy={challenge.author?.uid} // Possibility of author profile get deleted
                  creatorType={challenge.creatorType}
                  fieldToCompare={challenge.fieldToCompare}
                  game={challenge.game}
                  goal={"win"} // Not getting from backend
                  logoUrl={challenge.logoUrl}
                  name={challenge.name}
                  slug={challenge.slug}
                  author={challenge.author}
                  startDate={{
                    _seconds: challenge.startDate.seconds,
                    _nanoseconds: challenge.startDate.nanoseconds,
                  }}
                  endDate={{
                    _seconds: challenge.endDate.seconds,
                    _nanoseconds: challenge.endDate.nanoseconds,
                  }}
                />
              </div>
            );
          })}

        {isLoading
          ? Array.from(Array(5), (_, ind) => {
              return <ChallengeOverviwLoader key={ind} />;
            })
          : ""}
      </div>
    </>
  );
};
