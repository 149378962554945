const TRANSLATIONS_FR = {
  logitech: {
    signup: {
      headline:
        "Bienvenue aux journées de course Logitech McLaren G Challenge!",
      description:
        "Réglez votre temps au tour dans Forza Motorsport et affrontez les autres pilotes de votre magasin et de votre région. Vérifiez les résultats chaque semaine sur www.logitechgchallenge.com/race-days et sur Logitech G Discord.",
    },
    input: {
      headline: "Inscrivez-vous pour commencer",
      email: {
        label: "E-mail",
        placeholder: "jean.dupont@email.com",
      },
      store: {
        label: "Magasin",
        placeholder: "",
      },
      fullName: {
        label: "Nommer le premier Dernier",
        placeholder: "Jean Dupont",
      },
      userName: {
        label: "Nom d'utilisateur",
        placeholder: "jdupont",
      },
      dateOfBirth: {
        label: "Date de naissance",
        placeholder: "",
      },
      terms: {
        headline: "Conditions générales",
        label: "J'accepte ce qui précède",
        description:
          "Tous les participants doivent lire et comprendre les conditions générales avant de soumettre une participation au concours.",
        descriptionWithLink:
          "Tous les participants doivent lire et comprendre les  <1>conditions générales</1> avant de soumettre une participation au concours.",
      },
      submit: {
        label: "Soumettre",
      },
    },
  },
};

export default TRANSLATIONS_FR;
