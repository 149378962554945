import { Countdown } from "@/components/Countdown";
import React, { FC, useState } from "react";
import paper from "/assets/ChallengeHeadPaperRip.png";
import i18next from "i18next";
import calculateTimeLeft from "./../../../../src/features/challenge/helperFunctions/calculateTimeLeft";

export const BestgamerEventHead: FC<{
  title: string;
  startDate: {
    seconds: number;
    nanoseconds: number;
  };
  endDate: {
    seconds: number;
    nanoseconds: number;
  };
  brand: string;
}> = ({ title, startDate, endDate, brand }): JSX.Element => {
  const isBestGamer = brand === "BESTGAMER";
  const timeLeft = calculateTimeLeft(endDate);

  return (
    <div className="activity__head">
      <div className="activity__title">
        <h1>{title}</h1>

        {isBestGamer && (
          <div className="countdown__heading">
            <h2>
              {timeLeft !== null
                ? `${title} ${i18next.t("countdown.endsIn")}`
                : `${title} ${i18next.t("countdown.hasEnded")}`}
            </h2>
          </div>
        )}

        {isBestGamer && (
          <div className="activity__separator">
            <div className="activity__paper">
              <img src={paper} alt="" />
            </div>
          </div>
        )}
      </div>

      <div className="activity__countdown">
        <Countdown
          type="challenge"
          startDate={startDate}
          endDate={endDate}
          heading={isBestGamer ? false : true}
        />
      </div>
    </div>
  );
};
