import firebase from "firebase/app";
import { Challenge } from "./../../src/models/database/challenges/challenge.model";
import { brand } from "../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

export const getChallengeBasedOnClient = async (
  client: string,
  state: "ongoing" | "upcoming" | "finished"
) => {
  const challenge = await firebase
    .firestore()
    .collection("challenges")
    .where("status", "==", state)
    .where("client", "==", client)
    .where("eventCode", "==", brand.event.eventCode)
    .get();

  const challData = challenge?.docs?.[0]?.data() as Challenge;

  if (challData?.slug) {
    return challData as Challenge;
  }
};
