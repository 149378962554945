import React, { FC, useEffect } from "react";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { DailyTasksResponse } from "./types/dailyTasksTypes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { TasksSchedule } from "./components/TasksSchedule";
import { Spinner } from "@/components";
import { SingleTaskItem } from "./SingleTaskItem";
import BaseUserAvatar from "@/components/BaseUserAvatar";
import { DailyTasksLeaderboard } from "./DailyTasksLeaderboard";

export const DailyTasks: FC<{}> = ({}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [dailyTasksData, loading, error, fetch_dailyTasks] =
    useCloudFunctionCall<DailyTasksResponse>("getListOfDailyTasks", "");

  const [updateTaskData, loading_update, error2, update_dailyTasks] =
    useCloudFunctionCall<any>("updateUserTaskStatus", "");

  const updateTask = async (
    type: "quiz" | "participate",
    answerKey?: string
  ) => {
    const taskId = `${dailyTasksData.activeTask.day}_${dailyTasksData.activeTask.time}`;

    if (answerKey) {
      await update_dailyTasks({
        taskId: taskId,
        userId: currentUser.uid,
        type,
        answerKey, // for quiz task
      });
      await fetch_dailyTasks({});
    } else {
      await update_dailyTasks({
        taskId: taskId,
        userId: currentUser.uid,
        type,
      });
      await fetch_dailyTasks({});
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetch_dailyTasks({ day: 3 });
    };

    fetch();
  }, []);

  return (
    <div className="tasks">
      <div className="tasks__info-banner">
        BE IN TOP 10 ON THE LEADERBOARD - WIN COOL PRIZES
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Header */}
          <section className="tasks__heading">
            <div className="heading">
              <h1>Daily tasks</h1>
              <div className="heading__user">
                <BaseUserAvatar
                  userName={currentUser?.userName}
                  logoUrl={currentUser.profileLogoUrl}
                />
                <p>{dailyTasksData?.currentUserPoints} points</p>
              </div>
            </div>
          </section>

          {/* Current active task card */}
          <div>
            <div className="tasks__activity-slider">
              {dailyTasksData && (
                <SingleTaskItem
                  dailyTasksData={dailyTasksData}
                  updateTask={updateTask}
                  loading_update={loading_update}
                />
              )}
            </div>
          </div>

          {/* Leaderboard */}
          <div>
            <DailyTasksLeaderboard />
          </div>

          {/* Tasks schedule */}
          <section className="tasks__legend">
            {dailyTasksData && (
              <TasksSchedule
                dailyTasksData={dailyTasksData?.taskArray}
                activeTaskNmbr={dailyTasksData?.activeTask?.taskNumber}
              />
            )}
          </section>

          <section className="tasks__calculations">
            <p>HOW WE CALCULATE POINTS</p>
            <div>
              <p>Task done successfully:</p> <p>+3 points</p>
            </div>
            <div>
              <p>Task failed:</p> <p>+1 point</p>
            </div>
            <div>
              <p>Task skipped:</p> <p>0 points</p>
            </div>
          </section>
        </>
      )}
    </div>
  );
};
