const TRANSLATIONS_IT = {
  logitech: {
    signup: {
      headline:
        "Benvenuti ai giorni di gara della Logitech McLaren G Challenge!",
      description:
        "Imposta il tuo tempo sul giro in Forza Motorsport e gareggia contro gli altri piloti nel tuo negozio e nella tua regione. Controlla i risultati settimanalmente su www.logitechgchallenge.com/race-days e su Logitech G Discord.",
    },
    input: {
      headline: "Iscriviti per iniziare",
      email: {
        label: "E-mail",
        placeholder: "mario.rossi@email.com",
      },
      store: {
        label: "Negozio",
        placeholder: "Enter your store",
      },
      fullName: {
        label: "Nombre primero y apellido ",
        placeholder: "Mario Rossi",
      },
      userName: {
        label: "Nome utente",
        placeholder: "mrossi",
      },
      dateOfBirth: {
        label: "Data di nascita",
        placeholder: "Enter your date of birth",
      },
      terms: {
        headline: "Termini e condizioni",
        label: "Accetto quanto sopra",
        description:
          "Tutti i partecipanti devono leggere e comprendere i Termini e condizioni prima di inviare la propria iscrizione al concorso.",
        descriptionWithLink:
          "Tutti i partecipanti devono leggere e comprendere i <1>Termini e condizioni</1> prima di inviare la propria iscrizione al concorso.",
      },
      submit: {
        label: "Invia",
      },
    },
  },
};

export default TRANSLATIONS_IT;
