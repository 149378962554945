import React, { useState, useEffect, useCallback, FC } from "react";
import QueryDB from "@/api/queryDB/common";
import { CreateChallengeFormTypes } from "@/features/challenge/utils/CreateChallengeTypes";
import { createChallengeOptions } from "@/features/challenge/utils/CreateChallengeOptions";
import { CreateChallengeSelectMenu } from "../inputComponents/ChallengeSelectInput";
import { ChallengeCriteriaPicker } from "../inputComponents/ChallengeCriteriaPicker";
import { createToast } from "@/utils/toaster/createToast";
import { RadioInputsGroup } from "@/components";

type GeneralInputValuesProps = { text: string; value: string }[];

export const CreateChallengeStep1: FC<{
  values: CreateChallengeFormTypes;
  errors: any;
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  isEdit: boolean;
}> = ({ values, errors, setFieldValue, isEdit }): JSX.Element => {
  const [optionsFields, setOptionsFields] = useState<any>();

  const setOptionValue = async (targetName: string, value: any) => {
    return setOptionsFields((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  const [critType, setCritType] = useState<string>("");
  const [selectedCriteria, setSelectedCriteria] = useState<
    | [
        {
          [key: string]: any;
        },
      ]
    | []
  >([values.criteria] || []);

  const isArrayofObjects = (arr: any[]): boolean => {
    return Array.isArray(arr) && arr.length > 0 && typeof arr[0] === "object";
  };

  const isRacingGame = values.gameType.includes("f120");

  useEffect(() => {
    if (values.gameType) {
      QueryDB.getSingleDoc(
        "challengesFieldsToComparePublic",
        values.gameType
      ).then((result: any) => {
        if (!result) return;

        // Formatting
        Object.keys(result).map((listOption: string) => {
          const formattedList = [];
          if (!isArrayofObjects(result[listOption])) {
            Object.entries(result[listOption]).forEach(([key, value]: any) => {
              if (value) {
                return formattedList.push({
                  text: typeof value === "object" ? key : value,
                  value:
                    typeof value === "object"
                      ? Object.values(value)
                      : (value as any),
                });
              }
            });

            setOptionValue(listOption, formattedList);
            return;
          }

          if (isArrayofObjects(result[listOption])) {
            setOptionValue(listOption, result?.[listOption]?.reverse());
          }
        });
      });
    }
  }, [values.game]);

  const handleUpdateCriteriaList = useCallback(
    (
      type: "empty" | "add" | "remove" | "addEmpty",
      value?: number,
      key?: any
    ) => {
      let ghostArray = selectedCriteria as any;
      if (type === "empty") {
        return setSelectedCriteria([]);
      }

      if (type === "addEmpty") {
        setSelectedCriteria((prev) => ({ [""]: 1, ...prev }));
        ghostArray = [ghostArray, { [""]: 1 }];
      }

      if (type === "add") {
        if (key && value) {
          setSelectedCriteria((prev: any) => ({ [key]: value, ...prev }));
          if (critType === "advanced") {
            ghostArray = { ...values.criteria, [key]: value };
          } else {
            ghostArray = { [key]: value };
          }
        } else
          createToast({
            type: "info",
            message: "Please select a criteria and valid amount ( number )",
          });
      }

      if (type === "remove" && value && key) {
        if (ghostArray[key]) {
          delete ghostArray[key];
          setSelectedCriteria(ghostArray);
        }
      }

      if ("" in ghostArray) {
        delete ghostArray[""];
      }

      setFieldValue("criteria", ghostArray);
    },
    [selectedCriteria]
  );

  // Reset the criteria selection when switvhin between critTypes
  useEffect(() => {
    if (!isEdit) {
      setFieldValue("criteria", []);
      handleUpdateCriteriaList("empty");
    }
  }, [critType]);

  console.log("optionsFields", optionsFields);

  return (
    <>
      <>
        <RadioInputsGroup
          name="onlyWins"
          options={createChallengeOptions.onlyWins}
          label="Challenge type"
          error={errors && errors["onlyWins"] ? errors["onlyWins"] : undefined}
          setFieldValue={setFieldValue}
          initVal={values.onlyWins || "Record"}
        />
        {optionsFields?.fields !== undefined && (
          <ChallengeCriteriaPicker
            critType={critType}
            setCritType={setCritType}
            state={selectedCriteria}
            fields={optionsFields?.fields || []}
            setFieldValue={setFieldValue}
            handleUpdateCriteriaList={handleUpdateCriteriaList as any}
            errors={errors}
            name="criteria"
            isEdit={isEdit}
          />
        )}
        {optionsFields?.field !== undefined && (
          <ChallengeCriteriaPicker
            critType={critType}
            setCritType={setCritType}
            state={selectedCriteria}
            fields={optionsFields?.field || []}
            setFieldValue={setFieldValue}
            handleUpdateCriteriaList={handleUpdateCriteriaList as any}
            errors={errors}
            name="criteria"
            isEdit={isEdit}
          />
        )}
      </>

      {["PUBG", "Call of Duty: Warzone"].includes(values.game) && (
        <CreateChallengeSelectMenu
          name="teamSize"
          label="Team size"
          value={values.teamSize}
          setFieldValue={setFieldValue}
          errors={errors}
          placeholder="Team size"
          options={createChallengeOptions.teamSize}
          isEdit={isEdit}
          hasTooltip={false}
        />
      )}

      <div className="content__group">
        {optionsFields?.mapNames !== undefined && (
          <CreateChallengeSelectMenu
            name="mapNames"
            label={isRacingGame ? "Track" : "Map"}
            value={values.mapPool || values.mapNames?.[0]}
            setFieldValue={setFieldValue}
            errors={errors}
            placeholder={isRacingGame ? "Select track" : "Select map"}
            options={[
              { text: `${isRacingGame ? "Any track" : "Any map"}`, value: "" },
              ...(optionsFields?.mapNames || []),
              ...(optionsFields?.mapPool || []),
            ]}
            isEdit={isEdit}
            toolTipToShow="mapSelect"
            hasTooltip
          />
        )}

        {optionsFields?.mapModes !== undefined && (
          <CreateChallengeSelectMenu
            name="mapModes"
            label="Game mode"
            value={values.mapModes?.[0]}
            setFieldValue={setFieldValue}
            errors={errors}
            placeholder="Select mode"
            options={[
              { text: "Any mode", value: "" },
              ...(optionsFields?.mapModes || []),
            ]}
            isEdit={isEdit}
            toolTipToShow="gameModeSelect"
            hasTooltip
          />
        )}
      </div>

      <RadioInputsGroup
        name="type"
        options={createChallengeOptions.type}
        label="Access"
        error={errors && errors["type"] ? errors["type"] : undefined}
        setFieldValue={setFieldValue}
        initVal={values.type || ""}
      />
    </>
  );
};
