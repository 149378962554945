import React, { FC } from "react";
import i18next from "i18next";
import RacedaysLogo from "../../../../branchAndBrandSpecific/utils/brandVariables/assets/logitech/racedays.svg";
import Logo from "../../../../branchAndBrandSpecific/utils/brandVariables/assets/logitech/g-challenge-logo.svg";
import Image from "../../../../branchAndBrandSpecific/utils/brandVariables/assets/logitech/signup_image.webp";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import i18n from "@/tranlations/i18n";
import { languageOptions } from "@/utils/constants/languages";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Button, Spinner } from "@/components";

export const SignupLogitechForm: FC<{
  setFieldValue: (targetName: string, value: any) => Promise<void>;
  googleSheetinfo;
  handleCreateGoogleDoc: () => Promise<void>;
  _loading: boolean;
}> = ({ setFieldValue, googleSheetinfo, handleCreateGoogleDoc, _loading }) => {
  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
    setFieldValue("lang", e.target.value);
  };

  return (
    <div className="sign-up sign-up__logitech">
      <Container>
        <div className="sign-up__header">
          <div className="sign-up__logo">
            <img
              src={Logo}
              width={186}
              height={28}
              alt="Logitech McLaren G Challenge"
            />
          </div>
          <div className="sign-up__language">
            <div className="settings__group settings__group--language">
              <select
                onChange={(e) => {
                  handleLanguageChange(e);
                }}
                onBlur={(e) => {
                  handleLanguageChange(e);
                }}
              >
                {languageOptions.map((lang) => {
                  return (
                    <option
                      value={lang.value}
                      key={lang.value}
                      // onClick={() => {
                      //   handleLanguageChange(lang.value);
                      // }}
                    >
                      {lang.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </Container>

      <div className="sign-up__hero">
        <div className="sign-up__hero-image">
          <img src={Image} alt="Logitech McLaren G Challenge" />
        </div>
        <div className="sign-up__hero-content">
          <Container>
            <img
              src={RacedaysLogo}
              width={186}
              height={40}
              alt="Logitech McLaren G Challenge Racedays"
            />
            <h1>{i18next.t("logitech.signup.headline")}</h1>
            <p>{i18next.t("logitech.signup.description")}</p>
          </Container>
        </div>
      </div>

      <Container>
        <div className="sign-up__form">
          <h3>{i18next.t("logitech.input.headline")}</h3>

          {/* Email */}
          <div className="input">
            <BaseTextInput
              label={i18next.t("logitech.input.email.label")}
              textType="text"
              value={googleSheetinfo?.email}
              placeholder={i18next.t("logitech.input.email.placeholder")}
              setObjectState={setFieldValue}
              setObjectStateTargetName="email"
              isDisabled={_loading}
            />
          </div>

          {/* Store */}
          <div className="input">
            <BaseTextInput
              label={i18next.t("logitech.input.store.label")}
              textType="text"
              value={googleSheetinfo?.store}
              placeholder={i18next.t("logitech.input.store.placeholder")}
              setObjectState={setFieldValue}
              setObjectStateTargetName="store"
              isDisabled={true}
            />
          </div>

          {/* FullName */}
          <div className="input">
            <BaseTextInput
              label={i18next.t("logitech.input.fullName.label")}
              textType="text"
              value={googleSheetinfo?.fullName}
              placeholder={i18next.t("logitech.input.fullName.placeholder")}
              setObjectState={setFieldValue}
              setObjectStateTargetName="fullName"
              isDisabled={_loading}
            />
          </div>

          {/* UserName */}
          <div className="input">
            <BaseTextInput
              label={i18next.t("logitech.input.userName.label")}
              textType="text"
              value={googleSheetinfo?.userName}
              placeholder={i18next.t("logitech.input.userName.placeholder")}
              setObjectState={setFieldValue}
              setObjectStateTargetName="userName"
              isDisabled={_loading}
            />
          </div>

          {/* Date of Birth */}
          <div className="input">
            <BaseTextInput
              label={i18next.t("logitech.input.dateOfBirth.label")}
              textType="date"
              value={googleSheetinfo?.dateOfBirth}
              placeholder={i18next.t("logitech.input.dateOfBirth.placeholder")}
              setObjectState={setFieldValue}
              setObjectStateTargetName="dateOfBirth"
              isDisabled={_loading}
            />
          </div>

          <div className="input">
            <h4>{i18next.t("logitech.input.terms.headline")}</h4>
            <p>
              <Trans i18nKey="logitech.input.terms.descriptionWithLink">
                {i18next.t("logitech.input.terms.description")}
                <a
                  target="_blank"
                  href="https://docs.google.com/document/d/1jrht02Q7BCKrEAk3-wrrTYYQ-DGsIr9yJp7X0RhAh1Y/"
                >
                  {i18next.t("logitech.input.terms.headline")}
                </a>
              </Trans>
            </p>

            <BaseTextInput
              label={i18next.t("logitech.input.terms.label")}
              textType="checkmark"
              value={googleSheetinfo?.terms}
              placeholder=""
              setObjectState={setFieldValue}
              setObjectStateTargetName="terms"
              isDisabled={_loading}
            />
          </div>

          <Button
            variant="primary"
            onClickAction={() => {
              handleCreateGoogleDoc();
              console.log("UserCredentials", googleSheetinfo);
            }}
          >
            {_loading ? <Spinner /> : i18next.t("logitech.input.submit.label")}
          </Button>
        </div>
      </Container>
    </div>
  );
};
