import i18next from "i18next";
import LeaderboardUserItem from "./LeaderboardItem";

export const Challenge1337PageLeaderboard = ({ data }) => {
  return (
    <div>
      <div className="leaderboard">
        <div className="leaderboard__header">
          <div>{i18next.t("leet.leaderboard.username")}</div>
          <div>{i18next.t("leet.leaderboard.attempts")}</div>
        </div>
        {data?.length === 0 && (
          <div className="leaderboard__empty">
            There are currently no winners
          </div>
        )}
        {data &&
          data.map((doc, index) => {
            return (
              <div className="leaderboard__item" key={index}>
                <div className="item">
                  <div className="item__user">
                    <LeaderboardUserItem uid={doc.uid} />
                  </div>
                  <div className="item__score">{doc.attempts}</div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
