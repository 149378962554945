export const getTwitchAuthURL = () => {
  const baseURL = "https://id.twitch.tv/oauth2/authorize";
  const clientID = "24suo3la95vlcvew1eftfdy0ofxdoh";
  const scope = encodeURIComponent("user:read:email");
  const body = new URLSearchParams({
    response_type: "code",
    client_id: clientID,
    redirect_uri:
      "https://europe-west1-world-of-gamers-d663f.cloudfunctions.net/twitchAuth",

    state: "c3ab8aa609ea11e793ae92361f002671",
    grant_type: "authorization_code",
  });
  const URL = `${baseURL}?${body}&scope=${scope}`;
  return URL;
};
