import React, { FC, useEffect, useState } from "react";
import { useAtom } from "jotai";
import { clientAtom } from "../../../src/utils/atoms/atomClient";
import ProductImage from "./assets/jbl-quantum-one-headset-for-gaming--pdp_main-640.jpg";
import Product from "@/components/Product";
import LandingCallToAction from "@/features/landing/components/CallToAction";
import ctaImage from "./assets/jbl_challenge.webp";
import { getChallengeBasedOnClient } from "../../helperFunctions/getChallengeBasedOnClient";

const product = {
  image: {
    src: ProductImage,
    title: "JBL Quantum One",
  },
  productName: "JBL Quantum One (Placeholder)",
  retailPrice: 2690,
  eventPrice: 1990,
  discount: 700,
  usp: {
    string1: "Active Noise Cancelling tuned for gaming.",
    string2: "Optimized for PC, compatible with multiple platforms.",
    string3: "Be heard with the voice focus detachable boom mic.",
  },
  terms: "",
  currency: "SEK",
  url: "https://www.elgiganten.se/product/gaming/gamingtillbehor/gaming-headset/jbl-quantum-one-headset-for-gaming/175123",
};

const JBLPartnerPage: FC = (): JSX.Element => {
  const [client, setCurrentClient] = useAtom(clientAtom);
  setCurrentClient("JBL");
  const [challengeLink, setChallengeLink] = useState<string>("");

  // Fetch current active challenge logic
  const fetch = async () => {
    await getChallengeBasedOnClient(client, "ongoing").then((res) => {
      if (res.slug) {
        setChallengeLink(`/challenges/${res.slug}`);
      }
    });
  };

  // Fetch current active challenge on page load
  useEffect(() => {
    if (client === "JBL") {
      fetch();
    }
  }, [client]);

  return (
    <div className="partner">
      {/* <div className="partner__heading">
        <h2>Sound is Survival</h2>
        <p>
          Our store is filled with all the best in gaming and crazy offers. Here is a little peek at some of the offers we have with! And remember - they are only valid in our store at Dreamhack.
          
        </p>
      </div> */}

      {/* <Product
        image={{
          src: product.image.src,
          title: product.image.title,
        }}
        productName={product.productName}
        retailPrice={product.retailPrice}
        eventPrice={product.eventPrice}
        discount={product.discount}
        usp={{
          string1: product.usp.string1,
          string2: product.usp.string2,
          string3: product.usp.string3,
        }}
        terms={product.terms}
        currency={product.currency}
        url={product.url}
      /> */}

      <LandingCallToAction
        title={"JBL Quantum Aim Trial"}
        desc={
          "Put your agility and reflexes to the ultimate test in the CS2 Aim Trial Challenge! Navigate through our thrilling course filled with dynamic obstacles, sharp turns, and unexpected twists. Your mission: complete the course in record time. Are you ready to compete for the fastest time and claim victory? Lace up your virtual running shoes and prove your speed and skill in this exhilarating challenge!"
        }
        image={ctaImage}
        link={challengeLink}
        label={challengeLink !== "" ? "Join the challenge" : ""}
        float="right"
      />
    </div>
  );
};

export default JBLPartnerPage;
