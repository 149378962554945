import React, { useState } from "react";

import { BaseTextInput } from "./../../../src/components/BaseTextInput/BaseTextInput";
import { Button } from "./../../../src/components/Button/index";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { createToast } from "@/utils/toaster/createToast";

export const Challenge1337PageReset = () => {
  const password = "1337reset";
  const [passValue, setPassvalue] = useState<string>("");
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);

  const handle1337Reset = async () => {
    if (passValue === password) {
      setPassvalue("");
      setInputDisabled(true);
      const data1337ref = collectionRef.winners1337;
      const data = await data1337ref.get();

      const batchPromises = data.docs.map((document) =>
        data1337ref.doc(document.id).delete()
      );

      await Promise.all(batchPromises).then(() => {
        createToast({
          type: "info",
          message: "Leaderboard reset",
        });
      });
    }
  };

  return (
    <div className="leet__reset">
      <BaseTextInput
        label={"To reset the 1337 leaderboard, enter the password"}
        textType="password"
        value={passValue}
        setSingleState={(e) => setPassvalue(e)}
        isDisabled={inputDisabled}
      />

      <Button
        variant="primary"
        disabled={passValue !== password}
        onClickAction={handle1337Reset}
      >
        Reset 1337
      </Button>
    </div>
  );
};
