import React, { FC } from "react";
import Hero from "../../../src/components/PageHero/PageHero";
import HeroImage from "./assets/tcl_heroImage.jpg";
import HeroImageMobile from "./assets/tcl_mobile.webp";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";
import LandingCallToAction from "@/features/landing/components/CallToAction";
import TCL from "../../../src/features/landing/assets/tcl.webp";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { createToast } from "@/utils/toaster/createToast";
import { auth } from "@/api/firebase/firebase.config";
import Product from "@/components/Product";
import ProductImage from "./assets/tcl-50-mqled80-4k-mini-led-smart-tv-2023--pdp_zoom-3000--pdp_main-960.jpg";
import { useLocation } from "react-router-dom";
import { ForceCredentialPopup } from "@/components/ForcedCredentialPopup/ForcedCredentialPopup";
import CS2Log from "../CS2Log/CS2Log";

const pageHero = {
  image: {
    src: HeroImage,
    alt: "Midjourney AI art for Counter-Strike 2",
    title: "Support for CS2 added",
    mobile: HeroImageMobile,
  },
  heading: `98" 4K Smart TV`,
  description:
    "Latency kan være skuffende, især for spilentusiaster. Med TCL's nyeste teknologi DLG (Dual Line Gate) giver funktionen til automatisk justering af indhold en højere opdateringshastighed end nogensinde før og en super lav latenstid. Du er kun et tryk væk fra en langt mere problemfri spiloplevelse.",
  btn: {
    link: "",
    label: "",
  },
  authorName: "",
};

const product = {
  image: {
    src: ProductImage,
    title: `TCL 50" MQLED80 4K MINI-LED Smart-TV (2023)`,
  },
  productName: `TCL 50" MQLED80 4K MINI-LED Smart-TV (2023)`,
  retailPrice: 7499,
  eventPrice: 4444,
  discount: 3055,
  usp: {
    string1: "144Hz, 4x HDMI 2.1, 5,7 ms",
    string2: "AiPQ 3.0 Processor, Dolby Vision",
    string3: "Google TV, ALLM, VRR",
  },
  terms:
    "Offer valid during Dreamhack Summer 14/6 - 16/6 2024 while supplies last.",
  currency: "SEK",
  url: "https://www.elgiganten.dk/product/tv-lyd-smart-home/tv-tilbehor/tv/tcl-50-mqled80-4k-mini-led-smart-tv-2023/646528",
};

const TCLPartnerPage: FC = (): JSX.Element => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const setCurrentClient = useSetAtom(clientAtom);
  setCurrentClient("TCL");
  useDocumentTitle("TCL");
  // const isMissingEmail =
  //   !currentUser.email &&
  //   !currentUser.emailAddress &&
  //   !auth?.currentUser?.email;

  // const RenderForcedPopup =
  //   window.location.pathname.includes("popup") && isMissingEmail;

  // if (isMissingEmail) {
  //   createToast({
  //     type: "info",
  //     message: "Add your email address to claim a reward from an admin",
  //   });
  // }

  return (
    <div className="partner">
      {/* <div className="partner__heading">
        <h1>Don't miss out!</h1>
        <p>
          Find our booth inside Elgiganten's store, the 14th - 16th of June 2024
          at Elmia, Jönköping, Sweden.
        </p>
      </div>
      <Product
        image={{
          src: product.image.src,
          title: product.image.title,
        }}
        productName={product.productName}
        retailPrice={product.retailPrice}
        eventPrice={product.eventPrice}
        discount={product.discount}
        usp={{
          string1: product.usp.string1,
          string2: product.usp.string2,
          string3: product.usp.string3,
        }}
        terms={product.terms}
        currency={product.currency}
        url={product.url}
      /> */}

      <LandingCallToAction
        title={"CS2: Er du klar for en 1v1? 🎯🔥"}
        desc={
          "Ta utfordringen i Counter-Strike 2! Sett deg godt til rette og gå rett i duell mot vennene dine eller en tilfeldig motstander. Bevis ferdighetene dine i intense 1v1-kamper og vis hvem som er den beste på serveren."
        }
        image={TCL}
        link="/"
        label={""}
        float="right"
      />

      <h3>Se hvem som spiller akkurat nå</h3>
      <CS2Log client="TCL" />
    </div>
  );
};

export default TCLPartnerPage;
