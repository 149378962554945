// useRealtimeDB.ts
import { useState, useEffect } from "react";
import { database_1 } from "../../../../src//api/firebase/firebase.config";

type Props = {
  matchState: "match_started" | "match_ended";
  player1: {
    kills: number;
  };
  player2: {
    kills: number;
  };
  events: Events[];
};

type Events = {
  kills: number;
  deaths: number;
  weaponName: string;
  wasHeadshot: boolean;
  player: "Player 1" | "Player 2";
  timestamp: EpochTimeStamp;
};

const getCS2Log = (client: string) => {
  const [usersData, setUsersData] = useState<Props | null>(null);
  const [logs, setLogs] = useState<Events[] | null>(null);

  const realtimeDB = database_1
    .ref("cs2")
    .child(client)
    .child("match_01")
    .orderByKey();

  const getLeaderboardData = async () => {
    realtimeDB.on("value", async (snapshot) => {
      if (snapshot.val()) {
        setUsersData(snapshot.val());

        if (snapshot.val()?.events) {
          const dataArray = Object.entries(snapshot.val()?.events).map(
            ([key, value]: any) => ({
              key,
              ...value,
            })
          );

          dataArray.sort((a, b) => b.timestamp - a.timestamp);
          setLogs(dataArray);
        }
      }
    });
  };

  useEffect(() => {
    getLeaderboardData();
  }, []);

  return { usersData, logs };
};

export default getCS2Log;
