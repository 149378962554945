import { useEffect, useState } from "react";
import {
  collectionDoc,
  collectionRef,
} from "../collectionRef/collectionOperations";

export const useSingleChallengeData = ({
  data,
}: {
  data: { challengeId: string | undefined; currUserId: string | undefined };
}) => {
  const {
    challengeId,
    currUserId,
  }: { challengeId: string | undefined; currUserId: string | undefined } = data;

  const [dataResponse, setDataResponse] = useState<any>();
  const [loading, setLoading] = useState<any>();

  const fetchData = async () => {
    if (!challengeId) {
      throw new Error("Missing challenge id");
    }

    try {
      setLoading(true);
      const challenge = await collectionDoc.challenge(challengeId);
      const challengeData = challenge.data();

      let currUserProfile: any;

      if (currUserId) {
        try {
          currUserProfile = await collectionDoc.profiles(currUserId);
        } catch (error) {
          console.log("error");
        }
      }

      if (!challengeData) {
        throw new Error("Challenge not found!");
      }

      if (!challengeData.createdBy) {
        throw new Error("Challenge is missing a creator!");
      }

      const creatorId: string = challengeData.createdBy;

      const creatorProfile = await collectionDoc.profiles(creatorId);

      if (!creatorProfile.exists) {
        throw new Error("Creator profile not found!");
      }
      const currUserFavoriteChallenges: string[] =
        currUserProfile?.data()?.favoriteChallenges || [];

      const isInFavorites = currUserFavoriteChallenges.includes(challenge.id);

      const lastThreeParticipants = await collectionRef.junctionProfileChallenge
        .where("challengeId", "==", challenge.id)
        .limit(3)
        .get();

      const currUserChallengeDataDocument = currUserProfile
        ? (
            await collectionDoc.junctionProfileChallenge(
              `${currUserProfile.id}_${challenge.id}`
            )
          ).data()
        : undefined;

      const participantsCount = challengeData.participantsCount;

      const lastThreeParticipantsIds = lastThreeParticipants.docs.map(
        (doc) => doc.data().userId
      );

      const lastThreeParticipantsProfiles = await Promise.all(
        lastThreeParticipantsIds.map((id) => collectionDoc.profiles(id))
      );

      const isCreatedByCurrUser =
        challengeData.createdBy === currUserProfile?.id;

      const creatorUserFollowers: string[] =
        (await collectionDoc.userFollowers(creatorProfile.id)).data()
          ?.followers || [];

      const isCreatorFollowedByCurrentUser = creatorUserFollowers.includes(
        currUserProfile?.id || "-1"
      );

      setDataResponse({
        challengeData: challengeData,
        status: challengeData.status,
        challengeAdditionalData: challengeData.challengeAdditionalData,
        currUserProfile:
          currUserProfile?.exists && currUserChallengeDataDocument
            ? {
                profile: currUserProfile.data(),
                userChallengeData:
                  currUserChallengeDataDocument.userChallengeData,
              }
            : undefined,
        isInCurrUserFavorites: isInFavorites,
        isCurrUserParticipant: currUserChallengeDataDocument ? true : false,
        isCreatedByCurrUser,
        creatorProfile: creatorProfile.data() as any,
        participantsCount,
        lastThreeParticipantsProfiles: lastThreeParticipantsProfiles.reduce(
          (filtered: any, doc) => {
            if (doc.exists) {
              filtered.push(doc.data() as any);
            }
            return filtered;
          },
          []
        ),
        isCreatorFollowedByCurrentUser,
        creatorFollowersCount: creatorUserFollowers.length,
      });
      setLoading(false);
      return;
    } catch (error) {
      setLoading(false);

      console.log("error");

      if (error) {
        throw error;
      }

      throw new Error("Error fetching needed data!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data: dataResponse,
    loading: loading,
    error: false,
    fetch_challenge: fetchData,
  };
};
