import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase";
import { Button } from "react-bootstrap";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { ChatFriendsOnline } from "./ChatFriendsOnline";
import { ChatMessagesList } from "./ChatMessagesList";
import { SingleChat } from "./SingleChat";
import ChatSingleConversationUserInfo from "./ChatSingleConversationUserInfo";
import { useCommunityData } from "@/hooks/useCommunityData";

const ChatOverview: FC = (): JSX.Element => {
  const location = useLocation();
  const isMessagesPage = location.pathname.includes("/messages/");
  const [isChatOpen, setChatOpen] = useState(isMessagesPage);
  const [selectedChat, setSelectedChat] = useState<string | null>(null); // Holds the current selected chat id string  `id1_id2`
  const [searchChatQuerry, setSearchChatQuerry] = useState<string>(""); // Query when searching inside a chat
  const [chats, setChats] = useState<string[]>([]); // Array of chats (uid's)
  const [chatMedia, setChatMedia] = useState<string[]>([]); // List of all the current selected chats media files
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [loading, isLoading] = useState<boolean>(false);
  // const [community, loading_community, communityError, fetch_2] =
  //   useCloudFunctionCall<GetUserCommunityBasedOnSlug>(
  //     "getUserCommunityBasedOnSlug"
  //   );
  const [community, loading_community, communityError] = useCommunityData();

  // On Load and on chat change run useEffect

  useEffect(() => {
    //  Get array with all chats available for current user
    if (chats.length === 0) {
      // isLoading(true);
    }

    const getChats = async () => {
      if (chats) {
        setChats([]);
        const databaseRef = firebase
          .database()
          .ref("chat_usersIdJunction")
          .child(currentUser.uid!);

        databaseRef.on("value", async (snapshot) => {
          if (snapshot.val()) {
            const data =
              (snapshot.val() as {
                [key: string]: boolean;
              }) || [];
            const chatIds = Object.keys(data);

            setChats([...chatIds]);
            isLoading(false);
          }
        });
      }

      // Temp delay to allow firebase to render the data after loading everything,
      setTimeout(() => {
        isLoading(false);
      }, 2000);
    };

    if (currentUser.uid!) {
      getChats();
      // fetch_2({ userSlug: currentUser.slug });
    }
  }, [currentUser.uid!]);

  // Reset search in case of a chat change
  useEffect(() => {
    if (searchChatQuerry) {
      setSearchChatQuerry("");
    }
  }, [selectedChat]);

  return (
    <>
      <div className="chat open">
        <div className="chat__mobile">
          <div className="chat__left">
            {/* List with friends*/}
            {community && (
              <ChatFriendsOnline
                friends={community}
                chats={chats}
                setSelectedChat={setSelectedChat}
                loading={loading}
              />
            )}

            {selectedChat && (
              <Button
                className="chat__back-btn"
                onClick={() => {
                  setSelectedChat(null);
                }}
                size="sm"
              >
                Back to chats
              </Button>
            )}
            {/* List with exisiting chats */}
            <div className={`chat__list ${selectedChat ? "selected" : ""}`}>
              {community && (
                <ChatMessagesList
                  community={community}
                  isChatOpen={isChatOpen}
                  setChatOpen={setChatOpen}
                  setSelectedChat={setSelectedChat}
                  selectedChat={selectedChat}
                  chats={chats}
                  loading={loading}
                />
              )}
            </div>
          </div>

          <div className="chat__middle open">
            {selectedChat && community && (
              <ChatSingleConversationUserInfo
                community={community}
                setSearchChatQuerry={setSearchChatQuerry}
                searchChatQuerry={searchChatQuerry}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                chatMedia={chatMedia}
              />
            )}
            {!selectedChat && !chats && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {selectedChat && (
              <SingleChat
                searchChatQuerry={searchChatQuerry}
                selectedChat={selectedChat}
                setChatMedia={setChatMedia}
                chatMedia={chatMedia}
              />
            )}
          </div>
        </div>
        {/* {selectedChat && (
          <div className={`chat__right ${selectedChat ? "open" : ""}`}>
            <ChatMedia chatMedia={chatMedia} />
          </div>
        )} */}
      </div>
    </>
  );
};

export default ChatOverview;
