const TRANSLATIONS_ES = {
  logitech: {
    signup: {
      headline:
        "¡Bienvenido a los días de carrera del Logitech McLaren G Challenge!",
      description:
        "Establece tu tiempo de vuelta en Forza Motorsport y compite contra otros conductores en tu tienda y región. Consulte los resultados semanalmente en www.logitechgchallenge.com/race-days y Logitech G Discord.",
    },
    input: {
      headline: "Regístrate para empezar",
      email: {
        label: "Correo electrónico",
        placeholder: "juan.perez@email.com",
      },
      store: {
        label: "Almacenar",
        placeholder: "",
      },
      fullName: {
        label: "Nombre primero y apellido",
        placeholder: "Juan Pérez",
      },
      userName: {
        label: "Nombre de usuario",
        placeholder: "jperez",
      },
      dateOfBirth: {
        label: "Fecha de nacimiento",
        placeholder: "",
      },
      terms: {
        headline: "Términos y condiciones",
        label: "Acepto lo anterior",
        description:
          "Todos los participantes deben leer y comprender los Términos y condiciones antes de enviar una participación en el concurso.",
        descriptionWithLink:
          "Todos los participantes deben leer y comprender los <1>Términos y condiciones</1> antes de enviar una participación en el concurso.",
      },
      submit: {
        label: "Enviar",
      },
    },
  },
};

export default TRANSLATIONS_ES;
