import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import ProtectedRoutesAuth from "../src/pages/routes/ProtectedRoutesAuth";
import { loggedInRoutes } from "./routes/loggedInRoutes";
import ProtectedRoutesProfile from "../src/pages/routes/ProtectedRoutesProfile";
import { Landing } from "../src/features/landing";
import { brand } from "./utils/brandVariables/brandVariables";
import { PageDoesntExist } from "../src/pageDoesntExist";
import ErrorRoutes from "@/pages/routes/ErrorRoutes";
import { TermsOfUse } from "../branchAndBrandSpecific/features/staticPages/TermsOfUse";
import {
  CreateUsernameAndProfile,
  ForgotPassword,
  Login,
  PasswordReset,
  Signup,
  VerifyEmail,
} from "@/features/auth";
import { siteRoutes } from "./routes/siteRoutesList";
import { PrivacyPolicy } from "./features/staticPages/PrivacyPolicy";
import { EventQrCode } from "@/features/auth/pages/eventQrSignUp/EventsQr";
import { ScannedQR } from "@/features/auth/pages/eventQrSignUp/ScannedQR";
import Challenge1337Page from "./features/1337-challenge/1337.challenge.page";
import { GamesPage } from "./features/games/Games.page";
import { SpaceCoinsRedirect } from "./features/space-coins-redirect/spaceCoins.redirect";
import { TreasureHunt } from "./features/treasure-hunt/TreasureHunt";
import { TreasureHuntRedirect } from "./features/treasure-hunt/TreasureHuntRedirect";
import { Challenge1337Redirect } from "./features/1337-challenge/1337.challenge.redirect";
import { MultiplayerBrowserGameRedirect } from "./features/join-multiplayer-game-redirect/multiplayer-game.redirect";
import { LandingElgigantenLivestream } from "@/features/landing/Landing.Elgiganten.livestream.page";
import { Challenge1337Pagelarge } from "./features/1337-challenge/1337.challenge.page.large";
import { LandingElgiganten } from "@/features/landing/Landing.Elgiganten.page";
import { Spinner } from "@/components";
import { AuthDiscordRedirect } from "./../src/features/auth/pages/authRedirects/auth.discord.redirect";
import { SignupLiveStreamLayout } from "@/features/auth/pages/Signup.livestream.layout";
import { SignupLogitech } from "@/features/auth/pages/Signup.Logitech";
import { AuthTwitchRedirect } from "@/features/auth/pages/authRedirects/auth.twitch.redirect";

const isLogitech = brand.brandValue === "LOGITECH";
const isElgiganten = brand.brandValue === "ELGIGANTEN";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ProtectedRoutesAuth />, //for sign-up-1
    errorElement: <ErrorRoutes />,
    children: [
      {
        path: "/landing",
        element: brand.hasFeature.landing ? (
          isElgiganten ? (
            <SignupLiveStreamLayout />
          ) : isLogitech ? (
            <SignupLogitech customEvent={undefined} />
          ) : (
            <Landing />
          )
        ) : (
          <PageDoesntExist />
        ), //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/signincallback/",
        element: <PageDoesntExist />, //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/",
        element: brand.hasFeature.landing ? (
          isElgiganten ? (
            <SignupLiveStreamLayout />
          ) : isLogitech ? (
            <SignupLogitech customEvent={undefined} />
          ) : (
            <Landing />
          )
        ) : (
          <PageDoesntExist />
        ), //for landing page
        errorElement: <ErrorRoutes />,
      },

      // Auth
      {
        path: "/discord-auth",
        element: <AuthDiscordRedirect />, //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/twitch-auth",
        element: <AuthTwitchRedirect />, //for landing page
        errorElement: <ErrorRoutes />,
      },
      //
      {
        path: "/login",
        element: <Login />, //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: `/signup/:slug`,
        element: brand.hasFeature.landing ? (
          isElgiganten ? (
            <SignupLiveStreamLayout />
          ) : isLogitech ? (
            <SignupLogitech customEvent={undefined} />
          ) : (
            <Signup />
          )
        ) : (
          <PageDoesntExist />
        ), //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/signup",
        element: brand.hasFeature.landing ? (
          isElgiganten ? (
            <SignupLiveStreamLayout />
          ) : isLogitech ? (
            <SignupLogitech customEvent={undefined} />
          ) : (
            <Signup />
          )
        ) : (
          <PageDoesntExist />
        ), //for landing page
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/verify-email",
        element: <VerifyEmail />,
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/enter-username",
        element: <CreateUsernameAndProfile />,
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
        errorElement: <ErrorRoutes />,
      },
      {
        path: "/password-reset",
        element: <PasswordReset />,
        errorElement: <ErrorRoutes />,
      },
      {
        path: siteRoutes["terms"],
        element: <TermsOfUse />,
        errorElement: <ErrorRoutes />,
      },
      {
        path: siteRoutes["privacy-policy"],
        element: <PrivacyPolicy />,
        errorElement: <ErrorRoutes />,
      },
      {
        element: <EventQrCode />,
        path: siteRoutes["eventQrCode"],
      },
      {
        element: <ScannedQR />,
        path: `${siteRoutes["scanQrCodeAndSlug"]}:pcId`,
      },
      {
        element: isElgiganten ? (
          <Challenge1337Pagelarge />
        ) : (
          <PageDoesntExist />
        ),
        path: siteRoutes["leet-lg"],
      },

      {
        element: brand.hasFeature.treasure_hunt ? (
          <TreasureHuntRedirect />
        ) : (
          <PageDoesntExist />
        ),
        path: siteRoutes["treasureHuntRedirect"],
      },
      {
        element: isElgiganten ? <Challenge1337Redirect /> : <PageDoesntExist />,
        path: siteRoutes["leet-redirect"],
      },
      {
        element: brand.hasFeature.treasure_hunt ? (
          <TreasureHuntRedirect />
        ) : (
          <PageDoesntExist />
        ),
        path: `${siteRoutes["treasureHuntRedirect"]}:thid`,
      },
      // {
      //   element: brand.hasFeature.games ? <GamesPage /> : <PageDoesntExist />,
      //   path: siteRoutes["gamesPage"],
      // },
      // {
      //   element: brand.hasFeature.games ? GamesPage : PageDoesntExist,
      //   path: siteRoutes["gamesPage"],
      // },
      {
        element: brand.event.spacecoins_redirect ? (
          <SpaceCoinsRedirect />
        ) : (
          <PageDoesntExist />
        ),
        path: siteRoutes["spacecoins_redirect"],
      },

      {
        element: brand.event.spacecoins_redirect ? (
          <MultiplayerBrowserGameRedirect />
        ) : (
          <PageDoesntExist />
        ),
        path: siteRoutes["multiplayerBrowserGame_redirect"],
      },
      {
        path: "/",
        element: <ProtectedRoutesProfile />, // for sign-up-2
        errorElement: <ErrorRoutes />,
        children: loggedInRoutes.map((route) => {
          const Element = route.element;
          const Fallback = FallBackLoader;
          return {
            path: route.path,
            element: (
              <Suspense fallback={<Fallback />}>
                <Element />
              </Suspense>
            ),
          };
        }),
      },
    ],
  },
]);

function FallBackLoader() {
  return <h1>fallback...</h1>;
}
