import { brand } from "../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import CompletionBadge from "../../../../../../branchAndBrandSpecific/features/treasure-hunt/components/CompletionBadge";
import { TreasureHuntButtons } from "../../../../../../branchAndBrandSpecific/features/treasure-hunt/components/TreasureHuntButtons/TreasureHuntButtons";
import React, { FC, useRef, useState } from "react";
import { QrReader } from "react-qr-reader";

import Top from "../assets/treasurehunt_tasks_top.webp";
import Left from "../assets/treasurehunt_tasks_left.webp";
import Right from "../assets/treasurehunt_tasks_right.webp";
import Bottom from "../assets/treasurehunt_tasks_bottom.webp";

export const BestgamerTreasureHuntScore: FC<{
  scoreInProcentage: any;
  hasAllCodes: any;
  openCamera: any;
  setOpenCamera: any;
  setIsCameraConnected: any;
  treasureHuntCodes: {
    code: string;
    codeIndex: number;
    location: string;
  }[];
  usersCollectedData: {
    key: string;
    value: boolean;
  }[];
  handleResult: any;
  isCameraConnected: any;
}> = ({
  scoreInProcentage,
  hasAllCodes,
  openCamera,
  setOpenCamera,
  setIsCameraConnected,
  treasureHuntCodes,
  usersCollectedData,
  handleResult,
  isCameraConnected,
}) => {
  const [padding, setPadding] = useState(0);

  const handlePaddingChange = (newPadding: number) => {
    setPadding(newPadding);
  };

  const amountCodesFound = Object.keys(usersCollectedData)?.length + 1;
  const amountCodesToBeFound = Object.keys(treasureHuntCodes)?.length;

  const filteredUnfoundCodes = Object.keys(treasureHuntCodes)?.map((code) => {
    if (usersCollectedData?.[code]) {
      return undefined;
    } else {
      return treasureHuntCodes[code];
    }
  });

  return (
    <div className="score">
      {/* <div className="score__header"> */}
      <CompletionBadge scoreInProcentage={scoreInProcentage} />
      {scoreInProcentage === 100 && (
        <div className="treasurehunt__status">100&#37;</div>
      )}

      <div className="score__content">
        <div className="score__decorations">
          <img src={Top} alt="Top" className="score__frame score__frame--top" />
          <img
            src={Left}
            alt="Top"
            className="score__frame score__frame--left"
          />
          <img
            src={Right}
            alt="Top"
            className="score__frame score__frame--right"
          />
          <img
            src={Bottom}
            alt="Top"
            className="score__frame score__frame--bottom"
          />
        </div>
        <div className="content">
          <div
            className="content__status"
            style={{ paddingBottom: `${padding}px` }}
          >
            <h2>
              Location {amountCodesFound}/{amountCodesToBeFound}
            </h2>
            {hasAllCodes ? (
              <p>
                Congratulations on finishing the Treasure Hunt! Find an{" "}
                {brand.name} employee and show the screen to collect your prize.
              </p>
            ) : (
              <>
                <h3>Action:</h3>
                <p>Find the treasure location and scan the QR code</p>
                <h3>Clue:</h3>
                <p>{Object.values(filteredUnfoundCodes)?.[0]?.location}</p>
              </>
            )}
          </div>

          <TreasureHuntButtons
            onPaddingChange={handlePaddingChange}
            openCamera={openCamera}
            setOpenCamera={setOpenCamera}
            hasAllCodes={hasAllCodes}
            setIsCameraConnected={setIsCameraConnected}
          />
          {isCameraConnected && openCamera && (
            <div className="treasurehunt__camera">
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => handleResult(result)}
              />
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
