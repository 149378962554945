import React, { FC } from "react";
import { useAtom } from "jotai";
import { clientAtom } from "../../../src/utils/atoms/atomClient";
import LandingCallToAction from "../../../src/features/landing/components/CallToAction";
import ctaImage from "../../../src/features/landing/assets/cs2elgiganten.webp";
import CS2Log from "../CS2Log/CS2Log";

const CSPartnerPage: FC = (): JSX.Element => {
  const [client, setCurrentClient] = useAtom(clientAtom);
  setCurrentClient("ELGIGANTEN");

  return (
    <div className="partner">
      <LandingCallToAction
        title={"CS2: Er du klar til en 1v1?"}
        desc={
          "Tag udfordringen op i Counter-Strike 2! Sæt dig til rette, og gå direkte i duel mod dine venner eller en tilfældig modstander. Bevis dine skills i intense 1v1-kampe og vis, hvem der er den bedste på serveren."
        }
        image={ctaImage}
        link="/"
        float="left"
      />
      <h2>Se hvordan det går spillerne lige nu</h2>
      <CS2Log client="ELGIGANTEN" />
    </div>
  );
};

export default CSPartnerPage;
