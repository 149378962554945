import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge } from "react-bootstrap";
import i18next from "i18next";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import { routes } from "../utils/sideMenuRoutes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Elgiganten_siteRoutes } from "../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/Elgiganten_siteRoutes";
import { Logitech_siteRoutes } from "../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/Logitech_siteRoutes";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { BestGamer_siteRoutes } from "./../../../utils/brandVariables/siteRoutes/Bestgamer_siteRoutes";
import { getBestGamerChallengeRoutes } from "../utils/getBestGamerChallengeRoutes";
import { useBestGamerChallenges } from "../../../../branchAndBrandSpecific/helperFunctions/useBestGamerChallenges";

export const SideMenuLinks: React.FunctionComponent<
  any & { closeWindow?: () => void }
> = ({ currentViewName, closeWindow }) => {
  const [menuRoutes, setMenuRoutes] = useState(routes);
  const currenUser = useCurrentUserProfile((s) => s.user);
  const { challenges, loading } = useBestGamerChallenges({
    gamesToPick: ["subwaysurfers", "fortnite", "fallguys", "f12023"],
  });

  const handleBestGamerChallengeRoutes = async () => {
    // Challenges list gets overwritten, edit any info in getBestGamerChallengeRoutes() and not in BestGamer_siteRoutes!
    let routes = BestGamer_siteRoutes;

    const challengeRoutes = getBestGamerChallengeRoutes({
      challenges: challenges,
    });

    routes.challenges = challengeRoutes;
    setMenuRoutes(routes as any);
  };

  const isBestGamer = brand.brandValue === "BESTGAMER";
  const isElgiganten = brand.brandValue === "ELGIGANTEN";
  const isLogitech = brand.brandValue === "LOGITECH";

  useEffect(() => {
    if (isElgiganten) {
      setMenuRoutes(Elgiganten_siteRoutes as any);
    }
    if (isLogitech) {
      setMenuRoutes(Logitech_siteRoutes as any);
    }
    if (isBestGamer) {
      if (!loading && challenges) {
        handleBestGamerChallengeRoutes();
      }
    }
  }, [brand, menuRoutes, loading, challenges]);

  return (
    <nav className="primary-nav__nav">
      {Object.entries(menuRoutes).map(([key, value]) => (
        <div key={key} className="primary-nav__section">
          {key.trim() && value[0]?.title && (
            <div className="primary-nav__heading">
              <h4>{key}</h4>
            </div>
          )}

          <ul className="primary-nav__list">
            {key === "social" ? (
              <li
                className="primary-nav__item primary-nav__item--home"
                key={key}
                onClick={closeWindow}
              >
                <Link
                  to="/home"
                  className="primary-nav__link"
                  onClick={closeWindow}
                >
                  <span className="primary-nav__link--contents">
                    <HomeIcon />
                    <span>Homepage</span>
                  </span>
                </Link>
              </li>
            ) : (
              ""
            )}
            {value.map((v: any) => {
              if (v) {
                return (
                  <li
                    key={v.title}
                    className={`primary-nav__item ${
                      v.link === currentViewName ? "active" : ""
                    } ${v.game ? `primary-nav__game game-${v.game}` : ""} `}
                  >
                    <Link
                      to={`${
                        v.link.includes("http")
                          ? `${v.link}/${currenUser.uid}_${currenUser.userName}`
                          : v.link
                      }`}
                      className="primary-nav__link"
                      onClick={closeWindow}
                      target={`${v.link.includes("http") ? "_blank" : ""}`}
                    >
                      <span className="primary-nav__link--contents">
                        {v.logo && (
                          <v.logo width={!isBestGamer && 24} height={24} />
                        )}
                        <span>{i18next.t(v.title)}</span>
                      </span>
                      {v.title === "Messages" &&
                        localStorage!.getItem("hasMessages") === "true" &&
                        v.link !== currentViewName && (
                          <div className="badge-wrapper">
                            <Badge bg="primary">New</Badge>
                          </div>
                        )}
                    </Link>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      ))}
    </nav>
  );
};
