import react, { FC, useEffect, useState } from "react";
import { singleChallengeAltProps } from "../../../utils/singleChallengeProps";
import { siteRoutes } from "../../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { brand } from "../../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { Button, Spinner } from "@/components";
import i18next from "i18next";
import { Games } from "@/models/database/games/games.model";
import { collectionRef } from "@/api/collectionRef/collectionOperations";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router-dom";
import { participateInChallenge } from "@/features/challenge/helperFunctions/participateUserIntoChallenge";
import { Profile } from "@/models/database/profiles/profile.model";
import useGameInfo from "@/features/challenge/hooks/useGameInfo";

export const EventSingleChallengeActions: FC<{
  data: singleChallengeAltProps;
  isCurrUserParticipant: boolean;
  setIsCurrUserParticipant: (value: boolean) => void;
  currentUser: Profile;
  loading: boolean;
  setLoading: (value: boolean) => void;
}> = ({
  data,
  isCurrUserParticipant,
  setIsCurrUserParticipant,
  currentUser,
  loading,
  setLoading,
}): JSX.Element => {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const { gameData } = useGameInfo(data.gameType);

  useEffect(() => {
    if (data === undefined) {
      setError(true);
    }
  }, [data]);

  const participateCurrentUserIntoThatChallenge = async () => {
    if (!gameData) {
      return createToast({
        type: "error",
        message: "Could not find game info, please try again later",
      });
    }

    if (gameData.isManualSubmission) {
      return navigate(`${siteRoutes["submitScoreAndSlug"]}${data.slug}`, {
        state: {
          orgSlug: data.slug,
        },
      });
    }

    setLoading(true);

    if (!currentUser.uid) {
      navigate(siteRoutes["signUp1"]);
      return;
    }

    if (isCurrUserParticipant) {
      setLoading(false);
      createToast({
        type: "info",
        message: `Already joined \`${data.name}\``,
      });
      return;
    }

    const res = await participateInChallenge(
      navigate,
      data.type,
      gameData,
      currentUser.uid,
      data.slug
    );

    if (res) {
      setIsCurrUserParticipant(true);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      !isCurrUserParticipant &&
      gameData &&
      !isCurrUserParticipant &&
      brand.autoJoinChallenge
    ) {
      participateCurrentUserIntoThatChallenge();
    }
  }, [gameData]);

  const isInActiveChallenge =
    (data.status !== "finished" && isCurrUserParticipant) ||
    (data.status === "finished" && isCurrUserParticipant);
  const isNotFinished = data.status !== "finished";

  if (loading) {
    return;
  }

  if (error) {
    console.error("Error: Could not find challenge data");
    return;
  }

  if (!gameData?.isManualSubmission && isCurrUserParticipant && isNotFinished) {
    return <></>;
  }

  if (isNotFinished && !isCurrUserParticipant) {
    return (
      <div className="activity__actions">
        <Button
          variant="primary"
          data-challenges="card-icons"
          onClickAction={participateCurrentUserIntoThatChallenge}
          data-type="participate"
          disabled={false}
        >
          <>
            {!isCurrUserParticipant &&
              i18next.t("challenge.buttons.joinThisChallenge")}

            {gameData?.isManualSubmission &&
              i18next.t("challenge.buttons.submit")}
          </>
        </Button>
      </div>
    );
  }

  return <></>;
};
