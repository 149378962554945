import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import "../styles/index.scss";
import { AuthCard } from "../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { ReactComponent as IconCheck } from "../assets/iconCheck.svg";
import { ReactComponent as IconCross } from "../assets/iconCross.svg";
import { Button, Spinner } from "@/components";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";
export const PasswordReset: FC<{}> = () => {
  const navigate = useNavigate();
  const [userCredentials, setUserCredentials] = useState<{
    password: string;
    verifPassword: string;
  }>({
    password: "",
    verifPassword: "",
  });

  const [code, setCode] = useState<{
    0: number;
    1: number;
    2: number;
    3: number;
    4: number;
    5: number;
  }>({
    0: null,
    1: null,
    2: null,
    3: null,
    4: null,
    5: null,
  });
  const baseAuthenticationProps = {
    method: "email",
    platform: brand.brandValue,
  } as AuthenticationProps;

  //   Checks
  const isPasswordValid =
    /^(?=.*[0-9])(?=.*[!@#$%^&*()+\-\\[\]{};:'",.<>/?]).{6,}$/.test(
      userCredentials.password
    ); // Check if password contains every rule
  const passwordsMatch =
    userCredentials.password === userCredentials.verifPassword;
  const passwordRules = [
    {
      rule: i18next.t("auth.create.password.characters"),
      isValid: userCredentials.password.length >= 8,
    },
    {
      rule: i18next.t("auth.create.password.uppercase"),
      isValid: /^(?=.*[A-Z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.number"),
      isValid: /^(?=.*\d)/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.lowercase"),
      isValid: /^(?=.*[a-z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.match"),
      isValid:
        userCredentials.password !== "" &&
        userCredentials.password === userCredentials.verifPassword,
    },
    {
      rule: i18next.t("auth.create.password.special"),
      isValid: /^(?=.*[0-9])(?=.*[!@#$%^&*()+\-\\[\]{};:'",.<>/?]).{6,}$/.test(
        userCredentials.password
      ),
    },
  ];

  const setFieldValue = async (targetName: string, value: any) => {
    if (targetName === "passwordCode") {
      if (value !== "" && !Number(value)) {
        return setUserCredentials((prev: any) => ({
          ...prev,
          [targetName]: code,
        }));
      }
      if (code && value.length > 6) {
        return setUserCredentials((prev: any) => ({
          ...prev,
          [targetName]: code,
        }));
      }

      if (Number(value)) {
        return setUserCredentials((prev: any) => ({
          ...prev,
          [targetName]: value,
        }));
      }
    }

    return setUserCredentials((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Auth logic
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  useEffect(() => {
    if (!localStorage.getItem("authEmailLocal")) {
      return navigate("/login");
    }
    if (code && response) {
      if (response.status === "password-reset-success") {
        createToast({
          type: "success",
          message: "Password successfully updated",
        });
        setTimeout(() => {
          return navigate(response.redirectURL);
        }, 1500);
      }
      if (response.status === "weak-password") {
        createToast({
          type: "info",
          message:
            "Password is no strong enough, please try a different password.",
        });
      }
    }
  }, [response]);

  const handleNextStep = async () => {
    let concatenatedString = "";

    Object.keys(code).forEach((key) => {
      const value = code[key];
      if (typeof value === "number") {
        concatenatedString += value.toString();
      }
    });

    await authenticate({
      ...baseAuthenticationProps,
      action: "reset-password",
      newPassword: userCredentials.password,
      emailAddress: localStorage.getItem("authEmailLocal"),
      passwordResetCode: concatenatedString,
    });
  };

  // Page Document Title
  useDocumentTitle(i18next.t("auth.reset.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="signup">
          <AuthCard title={i18next.t("auth.reset.headline")} type="signup">
            {/* Password code */}
            <div className="input">
              <h4>{i18next.t("auth.reset.code")}</h4>
              <p>
                {i18next.t("auth.reset.digits")}{" "}
                {localStorage.getItem("authEmailLocal")}
              </p>
              <div className="input verify__input">
                {Array.from(Array(6), (_, index) => {
                  const key = `auth-${index}`;
                  return (
                    <input
                      className="base-input"
                      id={`authcode-${index}`}
                      type="text"
                      maxLength={1}
                      value={code[index]}
                      key={key}
                      disabled={_loading}
                      onPaste={async (e) => {
                        const pastedCode = await navigator.clipboard.readText();

                        if (
                          pastedCode.length === 6 &&
                          Object.values(code).every((value) => value === null)
                        ) {
                          setFieldValue("passwordCode", {
                            0: Number(pastedCode[0]),
                            1: Number(pastedCode[1]),
                            2: Number(pastedCode[2]),
                            3: Number(pastedCode[3]),
                            4: Number(pastedCode[4]),
                            5: Number(pastedCode[5]),
                          });
                        }
                      }}
                      onChange={(e) => {
                        if (!e.target.value && e.target.value !== "0") {
                          return setCode((prev: any) => ({
                            ...prev,
                            [index]: null,
                          }));
                        }

                        if (
                          Number(e.target.value) ||
                          Number(e.target.value) === 0
                        ) {
                          setCode((prev: any) => ({
                            ...prev,
                            [index]: Number(e.target.value),
                          }));

                          return document
                            .getElementById(`authcode-${index}`)
                            .focus();
                        }
                      }}
                    />
                  );
                })}
              </div>
            </div>

            {/* Password */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.password.create.label")}
                textType="password"
                value={userCredentials?.password}
                placeholder={i18next.t(
                  "auth.input.password.create.placeholder"
                )}
                setObjectState={setFieldValue}
                setObjectStateTargetName="password"
                error={
                  userCredentials.password &&
                  !isPasswordValid &&
                  i18next.t("auth.input.password.create.error")
                }
                functionOnEnter={async () => {
                  if (
                    passwordsMatch &&
                    userCredentials.password &&
                    userCredentials.verifPassword
                  ) {
                    handleNextStep();
                  }
                }}
              />
            </div>

            {/* Verif password */}
            {userCredentials.password && isPasswordValid && (
              <div className="input">
                <BaseTextInput
                  label={i18next.t("auth.input.password.repeat.label")}
                  textType="password"
                  value={userCredentials?.verifPassword}
                  placeholder={i18next.t(
                    "auth.input.password.repeat.placeholder"
                  )}
                  setObjectState={setFieldValue}
                  setObjectStateTargetName="verifPassword"
                  error={
                    userCredentials?.verifPassword &&
                    !passwordsMatch &&
                    i18next.t("auth.input.password.repeat.error")
                  }
                  functionOnEnter={async () => {
                    if (
                      passwordsMatch &&
                      userCredentials.password &&
                      userCredentials.verifPassword
                    ) {
                      handleNextStep();
                    }
                  }}
                />
              </div>
            )}
            <div className="signup__rules">
              <div className="rules">
                <p>{i18next.t("auth.create.password.headline")}:</p>
                <ul>
                  {passwordRules.map((passRule, index) => {
                    const key = `passRule_${index}`;
                    return (
                      <li className={passRule.isValid ? "valid" : ""} key={key}>
                        {passRule.isValid ? <IconCheck /> : <IconCross />}
                        {passRule.rule}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="signup__actions">
              <Button
                disabled={!isPasswordValid || !passwordsMatch}
                variant="primary"
                onClickAction={async (e) => {
                  handleNextStep();
                }}
              >
                {_loading ? <Spinner /> : i18next.t("auth.input.button.reset")}
              </Button>
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
