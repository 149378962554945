import { useEffect, useState } from "react";
import { GetUserProfileBasedOnUserSlug } from "../types/cloudFunctions/GetUserProfileBasedOnUserSlug";
import ProfileGeneralInfo from "./ProfileGeneralInfo";
import ProfileOnlineMembers from "./ProfileOnlineMembers";
import ProfileCardLoader from "@/components/skeletonLoaders/profileLoader/ProfileCardLoader";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useCommunityData } from "@/hooks/useCommunityData";
import { GamePassBox } from "@/components/GamePassCode/GamepassCode";
import { useAtom } from "jotai";
import { profileAtom } from "@/utils/atoms/profileAtoms/profileAtom";
import { getProfileData } from "@/api/firebaseQueries/getProfileData";

export default function ProfileAndCommunity({
  slug,
  setIsFriendsOfCurrentUser,
  children,
}: {
  slug: string;
  setIsFriendsOfCurrentUser: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
}) {
  const currentUserProfile = useCurrentUserProfile((s) => s.user);
  const belongToCurrentUser = !slug || slug === currentUserProfile.slug;

  const [profileDataAtom, setProfileDataAtom] = useAtom(profileAtom);
  const visitedprofileDoc = profileDataAtom?.[slug];

  const [userProfile, setUserProfile] =
    useState<GetUserProfileBasedOnUserSlug | null>(null);

  const [community, loading_community, communityError] = useCommunityData();
  const [fetchedProfileResponse, setFetchedProfileRespone] = useState<{
    _profile: any;
    loading_profile: boolean;
    profileGeneralError: any;
  }>();
  const profile = userProfile || fetchedProfileResponse?._profile;

  useEffect(() => {
    if (!slug) {
      return;
    }

    const fetchProfile = async () => {
      const { _profile, loading_profile, profileGeneralError } =
        await getProfileData({
          data: {
            currUserId: currentUserProfile.uid,
            userSlug: slug,
          },
        });
      setFetchedProfileRespone({
        _profile: _profile,
        loading_profile: loading_profile,
        profileGeneralError: profileGeneralError,
      });
    };

    if (belongToCurrentUser) {
      setUserProfile({
        ...currentUserProfile,
        belongsToCurrentUser: true,
        isFriendsOfCurrentUser: false,
        isFollowedByCurrentUser: false,
        gamesWithLinkedAccounts: [],
        isFriendsRequestPending: false,
        receivedFriendRequest: false,
      });
    } else {
      fetchProfile();
    }

    if (slug) {
    }
  }, [slug]);

  useEffect(() => {
    if (profile) {
      setIsFriendsOfCurrentUser(
        fetchedProfileResponse?._profile?.isFriendsOfCurrentUser
      );

      setProfileDataAtom({
        ...(profileDataAtom || []),
        [slug]: profile,
      });
    }
  }, [fetchedProfileResponse?._profile]);

  // Variables
  const hasFriendsFeature = brand.hasFeature.friends;
  const profileToRender = visitedprofileDoc || profile;

  return (
    <div className="profile">
      <div className="profile__grid">
        <div className="profile__card">
          {!fetchedProfileResponse?.loading_profile && profileToRender && (
            <ProfileGeneralInfo
              profileLogoUrl={profileToRender.profileLogoUrl}
              profileLogoUrlHistory={profileToRender.profileLogoUrlHistory}
              userName={profileToRender.userName}
              fullName={profileToRender.fullName}
              belongsToCurrUser={profileToRender.belongsToCurrentUser}
              isFollowedByCurrentUser={profileToRender.isFollowedByCurrentUser}
              isFriendsOfCurrentUser={profileToRender.isFriendsOfCurrentUser}
              isFriendsRequestPending={profileToRender.isFriendsRequestPending}
              receivedFriendRequest={profileToRender.receivedFriendRequest}
              // gamesWithLinkedAccounts={profile?.gamesWithLinkedAccounts}
              // isOnline={isOnline}
              uid={profileToRender?.uid}
              isPlaceholder={false}
              country={profileToRender.country}
              profileDescription={profileToRender.profileDescription}
              slug={profileToRender.slug}
              followersCount={profileToRender.followersCount}
              friendsCount={profileToRender.friendsCount}
              community={community}
              lang={profileToRender.lang || "eng"}
              error={fetchedProfileResponse?.profileGeneralError?.message}
              allPlatforms={profileToRender?.allPlatforms}
              termsAccepted={profileToRender.termsAccepted}
            />
          )}

          {/* PROFILE CARD SKELETON LOADER */}
          {fetchedProfileResponse?.loading_profile && <ProfileCardLoader />}
          {brand.brandValue === "GAMEPASS" && (
            <div>
              <GamePassBox belongsToCurrentUser />
            </div>
          )}
          {/* <ProfileAchievements /> */}
        </div>
        <div className="profile__feed">{children}</div>
        {hasFriendsFeature && (
          <div className="profile__friends">
            <ProfileOnlineMembers
              isLoading={loading_community}
              community={community}
              error={communityError?.message}
            />
          </div>
        )}
      </div>
    </div>
  );
}
