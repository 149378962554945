import { brand } from "../utils/brandVariables/brandVariables";

export const siteRoutes = {
  // Back home button, errorfallback etc
  home: "/home",
  landing: "/landing",
  default: brand.defaultNavigateUrl,
  // Auth
  login: "/login",
  signUp: "/signup",
  signUpSuccess: "/signup-success",
  signUp1: "/sign-up-1",

  // Profile
  profile: "/profile",
  profileAndSlug: "/profile/",
  profileSettings: "/profile-settings",
  profileTabAndSlug: "/profile-tab/",
  profileCommunityAndSlug: "/profile-community/",

  //  News
  news: "/news",
  newsAndSlug: "/news/",

  // Feed posts
  dashboard: "/dashboard",
  singlePostAndSlug: "/post/",

  // Challenges
  challenges: "/challenges",
  challengesAndSlug: "/challenges/",
  createChallenge: "/create-challenge",
  submitScoreAndSlug: "/submit-score/",

  // Challenges test
  newchallenges: "/challenges-alt",
  newchallenge: "/challenge-alt/",

  // Gather
  gathers: "/gathers",
  GathersAndSlug: "/gathers/",
  createGather: "create-gather",
  gatherSettings: "/gather-settings/",

  // Teams
  teams: "/teams",
  teamsAndSlug: "/teams/",
  createTeam: "/create-team",
  teamsMembersAndSlug: "/teams-members/",

  // Messages
  messages: "/messages",
  messagesAndSlug: "/messages/",

  // Qr Sign up
  eventQrCode: "/event-qr-code",
  scanQrCodeAndSlug: "/scan-qr/",

  // Notifications
  notifications: "/notifications",

  // Other | unused
  help: "/help",
  about: "/about",
  tracker: "/tracker",
  terms: "/terms",
  "privacy-policy": "/privacy-policy",
  zones: "/zones",
  friends: "/friends",
  search: "/search",
  winners: "/winners",
  schedule: "/schedule",

  partners: "/partners",
  partnersAndSlug: "/partners/",
  tcl: "/partners/tcl",
  tcl_popup: "/partners/tclpopup",
  logitech: "/partners/logitech",
  pcspecialist: "/partners/pcspecialist",
  jbl: "/partners/jbl",
  racing: "/partners/racing",
  cs2: "/partners/cs2",
  leet: "/1337-challenge",
  "leet-lg": "/1337-challenge-lg",
  "leet-redirect": "/1337-redirect",
  "leet-reset-trigger": "/1337-challenge-reset-trigger",
  dailytasks: "dailytasks",
  gamesPage: "/games",
  spacecoins_redirect: "/space-coins-redirect",
  multiplayerBrowserGame_redirect: "/join-multiplayer-game",
  treasureHunt: "/treasure-hunt/",
  treasureHuntRedirect: "/treasure-hunt-redirect/",
  tcl_logs: "/partners/tcl/logs",
  gchallenge: "/g-challenge/",

  // activity
  activityAndSlug: "/activity/",
};
