import React, { FC, useEffect, useState } from "react";
import useDocumentTitle from "../../../src/hooks/useDocumentTitle";
import { Button } from "@/components";
import firebase from "firebase/app";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { days, fullDays } from "@/utils/constants/weekDays";
import { createToast } from "@/utils/toaster/createToast";
import i18next from "i18next";
import LeaderboardUserItem from "./components/LeaderboardItem/index";
import { schedule1337 } from "./1337Schedule";

const Challenge1337Page: FC = (): JSX.Element => {
  const isLargeVersion = window.location.pathname === "/1337-challenge-lg";
  useDocumentTitle("1337 Challenge");
  const localAttempts = localStorage.getItem("attemptCount");
  const winner = localStorage.getItem("hasWon1337");
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [firstClickTime, setFirstClickTime] = useState<number | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(true);
  const [attemptCount, setAttemptCount] = useState<number>(
    localAttempts !== undefined ? Number(localAttempts) : 0
  );

  const today2 = new Date();
  const currentDay2 = fullDays[days[today2.getDay()]];

  const getCurrentSchedule = () => {
    const today = new Date();
    const currentDay = fullDays[days[today.getDay()]];
    const currentHour = today.getHours();

    const currentSchedule = schedule1337.find(
      (item) =>
        item.day === currentDay &&
        currentHour >= item.startHour &&
        currentHour < item.endHour
    );

    return currentSchedule;
  };

  const currentRunningSchedule = getCurrentSchedule();
  const [startHour, setStartHour] = useState<number>(
    currentRunningSchedule?.startHour
  );
  const [endHour, setEndHour] = useState<number>(
    currentRunningSchedule?.endHour
  );

  useEffect(() => {
    const updateSchedule = () => {
      const newSchedule = getCurrentSchedule();
      if (newSchedule) {
        setStartHour(newSchedule.startHour);
        setEndHour(newSchedule.endHour);
      } else {
        setStartHour(null);
        setEndHour(null);
      }
    };

    // Check and update the schedule immediately
    updateSchedule();

    // Set interval to check the schedule every minute
    const intervalId = setInterval(updateSchedule, 15000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const filterByHour = (data: LeaderboardItemTypes[]) => {
    return data?.filter((user) => {
      const winningDate = new Date(user.winningDate.seconds * 1000);
      const winningHour = winningDate.getHours();

      return (
        winningHour >= startHour &&
        winningHour <= endHour &&
        user.winningDay === currentRunningSchedule?.day
      );
    });
  };

  type LeaderboardItemTypes = {
    uid: string;
    attempts: number;
    userName: string;
    winningDate: any;
    score: number;
    winningDay: string;
  };
  const [leaderboardData, setLeaderboardData] =
    useState<LeaderboardItemTypes[]>();

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (firstClickTime !== null) {
      interval = setInterval(() => {
        setCurrentTime(Date.now() - firstClickTime);
      }, 10);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [firstClickTime]);

  useEffect(() => {
    localStorage.setItem("attemptCount", attemptCount.toFixed(0));
  }, [attemptCount]);

  const handleClick = async (key) => {
    if (key === "1" && firstClickTime) {
      return;
    }

    if (key === "2" && !firstClickTime) {
      return;
    }

    if (firstClickTime === null) {
      setFirstClickTime(Date.now());
    } else {
      const secondClickTime = Date.now();
      const timeDifference = secondClickTime - firstClickTime;
      setCurrentTime(timeDifference); // Update currentTime to match timeDifference
      setAttemptCount((prevCount) => prevCount + 1);

      // Move inside handleClick function to use timeDifference
      const setCurrentUserInLeaderboard = async () => {
        const data = {
          userName: currentUser.userName,
          uid: currentUser.uid,
          winningDate: firebase.firestore.FieldValue.serverTimestamp(),
          attempts: attemptCount + 1, // +1 because the setAttemptsCounts does not update attemptsCounts in this part of code at this time
          score: timeDifference,
        };
        await firebase
          .firestore()
          .collection("1337winners")
          .doc(`4d5${currentUser.userName}_${currentUser.uid}`)
          .set(data);

        setTimeout(() => {
          getAttempsLeaderboard();
        }, 2000);

        createToast({
          type: "success",
          message: `${i18next.t("leet.toast.success")}.`,
        });
      };

      // Check if timeDifference is between 1300 and 1400
      if (timeDifference >= 1331 && timeDifference <= 1341) {
        setCurrentUserInLeaderboard();
      }
      // Check if timeDifference is exactly 1337
      if (timeDifference === 1337) {
        setSuccess(true);
        localStorage.setItem("hasWon1337", "true");
      }

      setFirstClickTime(null);
    }
  };

  const getAttempsLeaderboard = async () => {
    firebase
      .firestore()
      .collection("1337winners")
      .limit(25)
      .onSnapshot((snapshot) => {
        const formattedData = snapshot.docs.map((doc) => {
          const today = new Date(doc.data().winningDate.seconds * 1000);
          const winningDay = fullDays[days[today.getDay()]];
          if (doc?.data()) {
            if (doc.id === `${currentUser.userName}_${currentUser.uid}`) {
              setAttemptCount(doc.data().attempts);
              setSuccess(true);
            }

            return {
              ...doc.data(),
              winningDay: winningDay,
            } as LeaderboardItemTypes;
          }
        });

        // if (formattedData.length !== 0) {
        setLeaderboardData(
          formattedData.sort((a, b) => a.attempts - b.attempts)
        );
        // }
      });
  };

  useEffect(() => {
    getAttempsLeaderboard();
  }, []);

  useEffect(() => {
    if (leaderboardData) {
      const currentUserHasScore1337 = leaderboardData.some(
        (user) => user.uid === currentUser.uid && user.score === 1337
      );

      if (currentUserHasScore1337) {
        setSuccess(true);
        localStorage.setItem("hasWon1337", "true");
      } else {
        setSuccess(false);
        localStorage.setItem("hasWon1337", "false");
      }
    }
  }, [leaderboardData]);

  const getDate = (seconds) => {
    if (!seconds) {
      return "---";
    }
    const date = new Date(seconds * 1000);
    const dateNo = date.getDay().toString();

    const time = `${String(date.getHours()).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;

    const formattedTime = `${i18next.t(`days.${dateNo}`)}, ${time}`;

    return formattedTime;
  };

  const hasScore1337 = leaderboardData
    ?.filter((user) => user.score === 1337)
    .slice(0, 11);

  const honorableMentions = leaderboardData
    ?.filter((user) => user.score !== 1337)
    .slice(0, 30);

  return (
    <div
      id="leet"
      className={`leet ${isLargeVersion ? "leet-lg" : ""}`}
      onKeyDown={(e) => {
        if (e.key === "1") {
          handleClick(e.key);
        } else if (e.key === "2") {
          handleClick(e.key);
        }
      }}
      tabIndex={0}
    >
      <div className="leet-challenge">
        <div className="leet-challenge__heading">
          <h1>{i18next.t("leet.headline")}</h1>
        </div>
        <div className="leet-challenge__description">
          <p>{i18next.t("leet.objective", { objective: "1337" })}</p>
        </div>

        <div className="leet-challenge__grid">
          <div>
            <div className="leet-challenge__time">
              {!success && (
                <>
                  {firstClickTime ? (
                    <div className="leet-challenge__actualTime">
                      <p>{currentTime}</p>
                    </div>
                  ) : (
                    <div className="leet-challenge__actualTime">
                      <p>{currentTime}</p>
                    </div>
                  )}
                </>
              )}

              {success && (
                <div className="leet-challenge__actualTime leet-challenge__actualTime--success">
                  <p>1337</p>
                </div>
              )}
            </div>

            <div className="leet-challenge__actions">
              {!success && (
                <Button
                  variant="primary"
                  disabled={success}
                  onClickAction={handleClick}
                >
                  {!firstClickTime ? (
                    <>
                      {success
                        ? i18next.t("leet.youWon")
                        : i18next.t("leet.start")}
                    </>
                  ) : (
                    <>{i18next.t("leet.stop")}</>
                  )}
                </Button>
              )}
            </div>
            {success && (
              <div className="leet-challenge__success">
                <p>{i18next.t("leet.congrats")} </p>
              </div>
            )}
            <div className="leet-challenge__attempts">
              <p>
                {i18next.t("leet.totalAttempts")}: {attemptCount}
              </p>
            </div>
          </div>

          {!isLargeVersion && (
            <div className="leet-challenge__leaderboard">
              <h2>{i18next.t("leet.leaderboard.headline")}</h2>
              <div className="leaderboard">
                <div className="leaderboard__header">
                  <div>{i18next.t("leet.leaderboard.username")}</div>
                  <div>{i18next.t("leet.leaderboard.attempts")}</div>
                </div>
                {hasScore1337?.length === 0 && (
                  <div className="leaderboard__empty">
                    {i18next.t("leet.honorable.noWinners")}
                  </div>
                )}
                {hasScore1337 &&
                  hasScore1337.map((doc, index) => {
                    return (
                      <div className="leaderboard__item" key={index}>
                        <div className="item">
                          <div className="item__user">
                            <LeaderboardUserItem uid={doc.uid} />
                          </div>
                          <div className="item__score">{doc.attempts}</div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          <div className="leet-challenge__honorable">
            <div className="honorable">
              <h2>{i18next.t("leet.honorable.headline")}</h2>
              <div className="honorable__list">
                {honorableMentions?.length === 0 && (
                  <div className="honorable__item">
                    {i18next.t("leet.honorable.none")}
                    {hasScore1337?.length !== 0 &&
                      i18next.t("leet.honorable.winnersOnly")}
                  </div>
                )}
                {honorableMentions &&
                  honorableMentions.map((doc, index) => {
                    return (
                      <div className="honorable__item" key={index}>
                        {doc.userName} ({doc.score})
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenge1337Page;
