import { RouterProvider } from "react-router-dom";
import "@github/markdown-toolbar-element";
import "../branchAndBrandSpecific/styles/main.scss";
import { router } from "./../branchAndBrandSpecific/Routes";
import { useEffect } from "react";
import { brand } from "../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import useDocumentTitle from "./hooks/useDocumentTitle";
import QueryDB from "./api/queryDB/common";
import Plausible from "plausible-tracker";

function App(): JSX.Element {
  const { trackEvent } = Plausible({
    trackLocalhost: false,
  });
  window.scrollTo({
    top: 0,
  });

  useEffect(() => {
    trackEvent("signup");
    window.scrollTo({
      top: 0,
    });
    let link: any = document.querySelector("link[rel~='icon']");

    if (!link) {
      link = document.createElement("link");
      if (link) {
        link.rel = "icon";
        document.head.appendChild(link);
        link.href = brand.siteIcon;
      }
    }
  }, []);

  useDocumentTitle("");

  const setMapsAndModes = async () => {
    const game = "f12023";

    if (!game) {
      return;
    }

    await QueryDB.updateDoc("challengesFieldsToComparePublic", game, {
      mapModes: [
        { text: "Event Mode", value: "Event Mode" },
        { text: "Grand Prix", value: "Grand Prix" },
        { text: "Grand Prix '23", value: "Grand Prix '23" },
        { text: "Time Trial", value: "Time Trial" },
        { text: "Splitscreen", value: "Splitscreen" },
        { text: "Online Custom", value: "Online Custom" },
        { text: "Online League", value: "Online League" },
        { text: "Career Invitational", value: "Career Invitational" },
        {
          text: "Championship Invitational",
          value: "Championship Invitational",
        },
        { text: "Championship", value: "Championship" },
        { text: "Online Championship", value: "Online Championship" },
        { text: "Online Weekly Event", value: "Online Weekly Event" },
        { text: "Story Mode", value: "Story Mode" },
        { text: "Career '22", value: "Career '22" },
        { text: "Career '22 Online", value: "Career '22 Online" },
        { text: "Career '23", value: "Career '23" },
        { text: "Career '23 Online", value: "Career '23 Online" },
        { text: "Benchmark", value: "Benchmark" },
      ],
      mapNames: [
        { text: "Vietnam - Circuito de Hanoi", value: "Vietnam" },
        {
          text: "Texas - Circuit of the Americas (Short)",
          value: "Texas Short",
        },
        { text: "Texas - Circuit of the Americas", value: "Texas" },
        { text: "Spain - Circuit De Barcelona-Catalunya", value: "Spain" },
        { text: "Singapore - Marina Bay Street Circuit", value: "Singapore" },
        {
          text: "Saudi Arabia - Jeddah Corniche Circuit",
          value: "Saudi Arabia",
        },
        { text: "Russia - Sochi Autodrom", value: "Russia" },
        { text: "Qatar - Losail International Circuit", value: "Qatar" },
        {
          text: "Portugal - Algarve International Circuit",
          value: "Portugal",
        },
        { text: "Netherlands - Circuit Zandvoort", value: "Netherlands" },
        { text: "Monza - Autodromo Nazionale Monza", value: "Monza" },
        { text: "Monaco - Circuit De Monaco", value: "Monaco" },
        { text: "Miami - Miami International Autodrome", value: "Miami" },
        { text: "Mexico - Autodromo Hermanos Rodriguez", value: "Mexico" },
        { text: "Las Vegas - Las Vegas Strip Circuit", value: "Las Vegas" },
        {
          text: "Japan - Suzuka International Racing Course (Short)",
          value: "Japan Short",
        },
        {
          text: "Japan - Suzuka International Racing Course",
          value: "Japan",
        },
        { text: "Hungary - Hungaroring", value: "Hungary" },
        {
          text: "Great Britain - Silverstone Circuit (Short)",
          value: "Great Britain Short",
        },
        {
          text: "Great Britain - Silverstone Circuit",
          value: "Great Britain",
        },
        {
          text: "Germany - Hockenheimring Baden-Württemberg",
          value: "Germany",
        },
        { text: "France - Circuit Paul Ricard", value: "France" },
        { text: "China - Shanghai International Circuit", value: "China" },
        { text: "Canada - Circuit Gilles-Villeneuve", value: "Canada" },
        { text: "Brazil - Autodromo Jose Carlos Pace", value: "Brazil" },
        { text: "Belgium - Circuit De Spa-Francorchamps", value: "Belgium" },
        {
          text: "Bahrain - Bahrain International Circuit (Short)",
          value: "Bahrain Short",
        },
        { text: "Bahrain - Bahrain International Circuit", value: "Bahrain" },
        { text: "Azerbaijan - Baku City Circuit", value: "Azerbaijan" },
        { text: "Austria - Red Bull Ring", value: "Austria" },
        { text: "Australia - Albert Park Circuit", value: "Australia" },
        { text: "Abu Dhabi - Yas Marina Circuit", value: "Abu Dhabi" },
      ],
    });
  };

  useEffect(() => {
    // setMapsAndModes();
  }, []);
  return <RouterProvider router={router} />;
}

export default App;
