import React, { FC, useEffect, useState } from "react";
import { getChallengeBasedOnClient } from "../../../branchAndBrandSpecific/helperFunctions/getChallengeBasedOnClient";
import { useNavigate } from "react-router-dom";
import { Spinner } from "@/components";

const LogitechPage: FC = (): JSX.Element => {
  const [challengeLink, setChallengeLink] = useState<string>("");
  const [client, setCurrentClient] = useState("LOGITECH");
  const navigate = useNavigate();

  // Fetch current active challenge logic
  const fetch = async () => {
    await getChallengeBasedOnClient(client, "ongoing").then((res) => {
      if (res.slug) {
        setChallengeLink(`/challenges/${res.slug}`);
      }
    });
  };

  // Fetch current active challenge on page load
  useEffect(() => {
    if (client) {
      fetch();
    }
  }, [client]);

  if (challengeLink) {
    navigate(challengeLink);
  }

  return null;
};

export default LogitechPage;
