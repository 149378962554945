import { createToast } from "@/utils/toaster/createToast";

export const getCameraState = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameras = devices.filter((device) => device.kind === "videoinput");

  if (cameras.length === 0) {
    createToast({
      type: "info",
      message: "No camera found",
    });
    return false;
  } else {
    return true;
  }
};
