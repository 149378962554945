import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import "../styles/index.scss";
import { AuthCard } from "../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { ReactComponent as IconCheck } from "../assets/iconCheck.svg";
import { ReactComponent as IconCross } from "../assets/iconCross.svg";
import { Button, Spinner } from "@/components";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate, useParams } from "react-router-dom";
import { FirebaseAuthMethods } from "../components/FirebaseAuthMethods";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";
import { authCredentialsAtom } from "@/utils/atoms/atomClient";
import { useSetAtom } from "jotai";

export const SignupLiveStreamLayout: FC<{}> = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const [userCredentials, setUserCredentials] = useState<{
    email: string;
    password: string;
    verifPassword: string;
  }>({
    email: slug ? decodeURI(slug) : "",
    password: "",
    verifPassword: "",
  });

  const baseAuthenticationProps = {
    method: "email",
    emailAddress: userCredentials.email,
    platform: brand.brandValue,
  } as AuthenticationProps;
  const setAtomCredentials = useSetAtom(authCredentialsAtom);

  //   Checks
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userCredentials.email);
  const isPasswordValid =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=!]).{8,}$/.test(
      userCredentials.password
    ); // Check if password contains every rule
  const passwordsMatch =
    userCredentials.password === userCredentials.verifPassword;
  const passwordRules = [
    {
      rule: i18next.t("auth.create.password.characters"),
      isValid: userCredentials.password.length >= 8,
    },
    {
      rule: i18next.t("auth.create.password.uppercase"),
      isValid: /^(?=.*[A-Z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.number"),
      isValid: /^(?=.*\d)/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.lowercase"),
      isValid: /^(?=.*[a-z])/.test(userCredentials.password),
    },
    {
      rule: i18next.t("auth.create.password.match"),
      isValid:
        userCredentials.password !== "" &&
        userCredentials.password === userCredentials.verifPassword,
    },
    {
      rule: i18next.t("auth.create.password.special"),
      isValid: /[@#$%^&+=!]/.test(userCredentials.password),
    },
  ];
  //

  const setFieldValue = async (targetName: string, value: any) => {
    return setUserCredentials((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Auth logic
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  useEffect(() => {
    if (response && userCredentials.email) {
      if (response.status === "email-verification-sent") {
        setAtomCredentials({
          email: userCredentials.email,
          password: userCredentials.password,
        });
        localStorage.setItem("authEmailLocal", userCredentials.email);
        createToast({ type: "success", message: response.payload });
        return navigate(response.redirectURL);
      }

      if (
        response.status === "weak-password" ||
        response.status === "email-already-taken" ||
        response.status === "failed-to-create-user"
      ) {
        return createToast({ type: "error", message: response.payload });
      }
    }
  }, [response]);

  const handleNextStep = async () => {
    await authenticate({
      ...baseAuthenticationProps,
      action: "signup",
      password: userCredentials.password,
    });
  };

  // Page Document Title
  useDocumentTitle(i18next.t("auth.livestream.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="livestream">
          <AuthCard title={i18next.t("auth.livestream.headline")} type="signup">
            <div className="livestream__actions">
              {(brand.hasAuth.bnet ||
                brand.hasAuth.google ||
                brand.hasAuth.steam ||
                brand.hasAuth.twitch) && (
                <div className="livestream__signup">
                  <FirebaseAuthMethods email={userCredentials.email} />
                </div>
              )}
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
