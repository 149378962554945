import i18next from "i18next";
import React, { FC } from "react";

export const EventDetails: FC<{
  goal: string;
  description: string;
  game: string;
}> = ({ goal, description, game }): JSX.Element => {
  return (
    <>
      {game && (
        <h2>
          {game} {i18next.t("strings.challenge")}
        </h2>
      )}

      {goal && (
        <>
          <h3>{i18next.t("challenge.description.what.headline")}</h3>
          <p>{goal}</p>
        </>
      )}

      {description && (
        <>
          <h3>{i18next.t("challenge.description.how.headline")}</h3>
          <p>{description}</p>
        </>
      )}
    </>
  );
};
