import { Spinner } from "@/components";
import React, { FC } from "react";

export const SignupLogitechLoader: FC = () => {
  return (
    <div className="sign-up sign-up__loader">
      <div className="loader">
        <Spinner />
        <h1>Getting your account ready</h1>
        <p>
          Please do not refresh the page. <br />
          <small>You will be redirected in a second.</small>
        </p>
      </div>
    </div>
  );
};
