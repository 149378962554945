import firebase from "firebase";

import { Query_Imgix } from "@/api/imgix";
import { createToast } from "@/utils/toaster/createToast";
import QueryDB from "@/api/queryDB/common";
import { CreateChallengeFormTypes } from "@/features/challenge/utils/CreateChallengeTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";

type ChallengeAdditionalData = {
  fieldToCompare: string[];
  fieldToCompareCoefficients: {
    [fieldToCompareName: string]: number;
  };
  mapModes?: string[];
  mapNames?: string[];
  mapPool?: string;
};

type Challenge = {
  name: string;
  createdBy: string;
  creatorType: "Community" | "Sponsor" | string;
  description: string;
  endDate: firebase.firestore.Timestamp | string | Date;
  game: string;
  gameType: "lol" | "wz" | "pubg" | string;
  logoUrl: string;
  onlyWins?: boolean;
  slug: string;
  startDate: firebase.firestore.Timestamp | string | Date;
  type: "public" | "private";
  goal: string;
  region: string;
  pointCountingType: "Record" | "Cumulative";
  teamSize: "All" | "Solo" | "Duo" | "Trio" | "Quad";
  challengeAdditionalData: ChallengeAdditionalData;
  hasFinalPoints: boolean;
  status: string;
  client: string;
};

const submit = async (
  values: CreateChallengeFormTypes,
  slug: string,
  isEdit: boolean
) => {
  let firebaseFileURL: any;

  if (values?.logoUrl.name) {
    firebaseFileURL = await Query_Imgix.convertImageToImgIX(
      values?.logoUrl,
      `challenge/${slug.trim()}`
    );
  } else {
    firebaseFileURL = values.logoUrl;
  }

  const coefficients = { ...values.criteria };

  if ("" in coefficients) {
    delete coefficients[""];
  }

  const checkStatusDate = () => {
    if (new Date(values.duration[0]) <= new Date(Date.now())) {
      return "ongoing";
    }
    return "upcoming";
  };

  const challenge: Challenge = {
    name: values.name,
    createdBy: firebase.auth().currentUser?.uid || "",
    creatorType: "Community",
    description: values.description || "",
    endDate: values.duration[1],
    game: values.game,
    gameType: values.gameType!,
    logoUrl: firebaseFileURL || "",
    onlyWins: false,
    slug,
    startDate: values.duration[0],
    type: values.type as "public" | "private",
    goal: values.goal,
    region: values.region,
    pointCountingType: values.onlyWins as "Record" | "Cumulative",
    teamSize: values.teamSize as "All" | "Solo" | "Duo" | "Trio" | "Quad",
    challengeAdditionalData: {
      fieldToCompare: Object.keys(coefficients),
      fieldToCompareCoefficients: coefficients,
      mapModes: values.mapModes || [],
      mapNames: values.mapNames || [],
      mapPool: values.mapPool || null,
    },
    hasFinalPoints: false,
    client: brand.brandValue,
    status: checkStatusDate(),
  };

  // UPDATE
  if (isEdit) {
    await QueryDB.updateDoc("challenges", challenge.slug, {
      name: challenge.name,
      goal: challenge.goal,
      type: challenge.type || "",
      description: challenge.description,
      logoUrl: challenge.logoUrl,
    })
      .then(() => {
        createToast({
          type: "success",
          message: `Challenge \`${challenge.name}\` has been updated!`,
        });
      })
      .catch(() => {
        createToast({
          type: "error",
          message: `Something went wrong trying to update your challenge.`,
        });
      });
  } else {
    // CREATE
    await QueryDB.createNewDoc("challenges", challenge, challenge.slug)
      .then(() => {
        createToast({
          type: "success",
          message: `Challenge \`${challenge.name}\` has been created!`,
        });
      })
      .catch((res) => {
        createToast({
          type: "error",
          message: `Something went wrong trying to create your challenge.`,
        });
      });
  }
};

export default submit;
