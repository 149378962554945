import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import { AuthCard } from "../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { Button, Spinner } from "@/components";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { createToast } from "@/utils/toaster/createToast";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";

export const ForgotPassword: FC<{}> = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const baseAuthenticationProps = {
    method: "email",
    emailAddress: email,
    platform: brand.brandValue,
  } as AuthenticationProps;

  useEffect(() => {
    if (email && response) {
      if (response.status === "email-sent") {
        createToast({
          type: "success",
          message: response.payload,
        });
        localStorage.setItem("authEmailLocal", email);
        return navigate(response.redirectURL);
      }

      if (
        response.status === "not-found" ||
        response.status === "email-sent-failed"
      ) {
        createToast({
          type: "error",
          message: response.payload,
        });
        return navigate(response.redirectURL);
      }
    }
  }, [response]);

  // Page Document Title
  useDocumentTitle(i18next.t("auth.forgot.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="signup">
          <AuthCard
            title={i18next.t("auth.forgot.headline")}
            type="forgot-password"
          >
            {/* Username */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.email.label")}
                textType="text"
                value={email}
                placeholder={i18next.t("auth.input.email.placeholder")}
                setSingleState={setEmail}
                error={
                  email &&
                  !isEmailValid &&
                  i18next.t("auth.forgot.error.invalid")
                }
                functionOnEnter={async () => {
                  if (email && isEmailValid) {
                    await authenticate({
                      ...baseAuthenticationProps,
                      action: "forget-password",
                    });
                  }
                }}
              />
            </div>
            <div className="signup__actions">
              <Button
                variant="primary"
                disabled={_loading || !isEmailValid}
                onClickAction={async (e) => {
                  await authenticate({
                    ...baseAuthenticationProps,
                    action: "forget-password",
                  });
                }}
              >
                {_loading ? (
                  <Spinner />
                ) : (
                  i18next.t("auth.input.button.send_link")
                )}
              </Button>
              <Button variant="link" onClickAction={() => navigate("/login")}>
                {i18next.t("auth.input.button.cancel")}
              </Button>
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
