export const languages = ["eng", "dan"];

export const language = [
  {
    label: "English",
    value: "eng",
  },
  {
    label: "Danish",
    value: "dan",
  },
];

export const languageOptions = [
  {
    label: "English",
    value: "eng",
  },
  {
    label: "Norsk",
    value: "no",
  },
  {
    label: "Français",
    value: "fr",
  },
  {
    label: "Deutsch",
    value: "de",
  },
  {
    label: "Italiano",
    value: "it",
  },
  {
    label: "Español",
    value: "es",
  },
  {
    label: "Język polski",
    value: "pl",
  },
];
