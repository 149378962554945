import React, { FC, useEffect, useState } from "react";
import firebase from "firebase";
import { Badge } from "react-bootstrap";
import { CommunityMember } from "./types/UserCommunity";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import BaseLogo from "@/components/BaseLogo/BaseLogo";

export const ChatSingleUserItem: FC<{
  community: GetUserCommunityBasedOnSlug;
  currentChatId: string;
  searchQuerry: string;
  selectedChat: string | null;
  handleChatOpen: any;
  isChatOpen: boolean;
}> = ({
  community,
  currentChatId,
  searchQuerry,
  selectedChat,
  handleChatOpen,
  isChatOpen,
}): JSX.Element => {
  const [senderId, receiverId] = currentChatId.split("_");
  const [lastMessage, setLastMessage] = useState<any>();
  const [unreadCounter, setUnreadCounter] = useState<any>();
  const currentUser = useCurrentUserProfile((s) => s.user);

  const otherUserId = senderId === currentUser?.uid ? receiverId : senderId;

  const otherUser = community.all.users.find(
    (user: CommunityMember) =>
      user?.uid === otherUserId &&
      (user.userName.toLowerCase().includes(searchQuerry.toLowerCase()) ||
        user?.fullName?.toLowerCase().includes(searchQuerry.toLowerCase()))
  );
  const databaseRef = firebase.database().ref("chat").child(currentChatId);

  useEffect(() => {
    localStorage.removeItem("hasMessages");
    databaseRef.on("value", (snapshot) => {
      const { unread, ...data } = snapshot.val();
      const dataObj = Object.values(data)[
        Object.values(data).length - 1
      ] as any;

      if (unread[currentUser.uid] !== 0) {
        localStorage.setItem("hasMessages", "true");
      } else {
        localStorage.removeItem("hasMessages");
      }
      if (!dataObj.content?.message && !dataObj?.content?.imageURL) {
        dataObj!.content!.message = "New chat started";
      }
      if (!dataObj.content?.message && dataObj?.content?.imageURL) {
        dataObj!.content!.message = "Sent a photo";
      }
      if (!dataObj.content?.message && dataObj?.content?.videoURL) {
        dataObj!.content!.message = "Sent a video";
      }
      if (!dataObj.content?.message && dataObj?.content?.fileURL) {
        dataObj!.content!.message = "Sent a photo";
      }

      setUnreadCounter(unread[currentUser.uid]);
      setLastMessage(dataObj);
    });
  }, [currentChatId, selectedChat]);
  const isSelectedChat = currentChatId === selectedChat;

  return (
    <>
      {otherUser && (
        <div
          key={currentChatId}
          className={`chat-messages__user ${isChatOpen ? "open" : ""} ${
            isSelectedChat ? "selected" : ""
          }`}
          onClick={() => handleChatOpen(currentChatId)}
          role="button"
          aria-hidden
        >
          <div className="chat-messages__user--info">
            <div className="chat-messages__user--image">
              <BaseLogo
                logoSrc={otherUser.profileLogoUrl}
                isPlaceHolder={undefined}
                name={otherUser.userName}
              />
            </div>
            <div className="chat-messages__preview">
              <h4>{otherUser.userName}</h4>
              <span>{lastMessage?.content?.message}</span>
            </div>
          </div>
          <div className="chat-messages__date">
            <span>sun</span>
            {unreadCounter !== 0 && (
              <div className="badge-wrapper">
                <Badge bg="primary">{unreadCounter}</Badge>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
