import i18next from "i18next";
import React, { FC, useEffect, useState } from "react";
import calculateTimeLeft from "../../features/challenge/helperFunctions/calculateTimeLeft";

export const Countdown: FC<{
  type: string;
  startDate: {
    seconds: number;
    nanoseconds: number;
  };
  endDate: {
    seconds: number;
    nanoseconds: number;
  };
  heading?: boolean;
  showDays?: boolean;
}> = ({ type, endDate, heading, showDays }): JSX.Element => {
  const [degree, setDegree] = useState<number>(0);

  useEffect(() => {
    let degreeCounter = 0;

    setInterval(() => {
      if (degreeCounter >= 360) {
        degreeCounter = 0;
        setDegree(degreeCounter);
      }
      degreeCounter++;
      setDegree(degreeCounter);
    }, 10);
  }, []);

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, [endDate]);

  // Variables
  const moreThanOneDay = timeLeft !== null && timeLeft.days > 0;
  const bodyClasses = moreThanOneDay
    ? "countdown__body"
    : "countdown__body countdown__body--3col";

  const style = {
    background: `linear-gradient(var(--bg-item-primary), var(--bg-item-primary)) padding-box, linear-gradient( ${degree}deg, var(--clr-primary) -0.01%, var(--clr-primary) 25%, rgba(9, 246, 104, 0) 40%, rgba(9, 246, 104, 0) 50%, rgba(9, 246, 104, 0) 90%, rgba(9, 246, 104, 0) 100% ) border-box`,
  };

  return (
    <div className="countdown">
      {heading && (
        <div className="countdown__heading">
          <h2>
            {timeLeft !== null
              ? `${i18next.t(`strings.${type}`)} ${i18next.t(
                  "countdown.endsIn"
                )}`
              : `${i18next.t(`strings.${type}`)} ${i18next.t(
                  "countdown.hasEnded"
                )}`}
          </h2>
        </div>
      )}
      {timeLeft !== null && (
        <div className="countdown__content">
          <div className="countdown__time" style={style}>
            <div className={bodyClasses}>
              {showDays && moreThanOneDay && (
                <div className="countdown__item">
                  <div className="countdown__number">{timeLeft.days}</div>
                  <div className="countdown__label">
                    {timeLeft.days === 1
                      ? i18next.t("countdown.days.singular")
                      : i18next.t("countdown.days.plural")}
                  </div>
                  <div className="countdown__separator">:</div>
                </div>
              )}
              <div className="countdown__item">
                <div className="countdown__number">
                  {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
                </div>
                <div className="countdown__label">
                  {timeLeft.hours === 1
                    ? i18next.t("countdown.hours.singular")
                    : i18next.t("countdown.hours.plural")}
                </div>
                <div className="countdown__separator">:</div>
              </div>
              <div className="countdown__item">
                <div className="countdown__number">
                  {timeLeft.minutes < 10
                    ? `0${timeLeft.minutes}`
                    : timeLeft.minutes}
                </div>
                <div className="countdown__label">
                  {timeLeft.minutes === 1
                    ? i18next.t("countdown.minutes.singular")
                    : i18next.t("countdown.minutes.plural")}
                </div>
                <div className="countdown__separator">:</div>
              </div>
              <div className="countdown__item">
                <div className="countdown__number">
                  {timeLeft.seconds < 10
                    ? `0${timeLeft.seconds}`
                    : timeLeft.seconds}
                </div>
                <div className="countdown__label">
                  {timeLeft.seconds === 1
                    ? i18next.t("countdown.seconds.singular")
                    : i18next.t("countdown.seconds.plural")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
