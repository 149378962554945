import firebase from "firebase";
import { firestore } from "../firebase/firebase.config";
import { Challenge } from "@/models/database/challenges/challenge.model";
import { ChallengeAdditionalData } from "@/models/database/challenges/challengeAdditionalData.model";
import { JunctionProfileChallenge } from "@/models/database/challenges/junctionProfileChallenge.model";
import { Gather } from "@/models/database/gathers/gather.model";
import { GatherPost } from "@/models/database/gathers/gatherPost.model";
import { GatherPostComment } from "@/models/database/gathers/gatherPostComment.model";
import { UserGatherJunction } from "@/models/database/gathers/userGatherJunction.model";
import { News } from "@/models/database/news/News.model";
import { NewsActivity } from "@/models/database/news/newsActivity.model";
import { Brand } from "@/models/database/partners/HP/Brand.model";
import { Training } from "@/models/database/partners/HP/Training.model";
import { Post } from "@/models/database/posts/post.model";
import { Comment } from "@/models/database/posts/postComment.model";
import { Like } from "@/models/database/posts/postLike.model";
import { Profile } from "@/models/database/profiles/profile.model";
import { UsersFollowers } from "@/models/database/profiles/usersFollowers.model";
import { Report } from "@/models/database/reports/reports";
import { Submission } from "@/models/database/submissions/Submission.model";
import { Team } from "@/models/database/teams/team.model";
import { TeamFollowers } from "@/models/database/teams/teamsFollowers.model";
import { TeamTournamentJunction } from "@/models/database/teams/teamTournamentJunction.model";
import { UserTeamJunction } from "@/models/database/teams/userTeamJunction.model";
import { Tournament } from "@/models/database/tournaments/tournament.model";
import { Games } from "@/models/database/games/games.model";

/*
returns the firebase collection reference with type.
*/
const collectionRefWithType = <Type>(collectionName: string) =>
  firestore.collection(
    collectionName
  ) as firebase.firestore.CollectionReference<Type>;

// for comments/likes as subcollection for posts.
// type P -> Type of parent collection
const subCollectionRefWithType = <T, P>(
  postId: string,
  subCollectionName: "comments" | "likes" | string,
  parentCollectionName: string
) =>
  collectionRefWithType<P>(parentCollectionName)
    .doc(postId)
    .collection(subCollectionName) as firebase.firestore.CollectionReference<T>;

export const collectionRef = {
  admin: collectionRefWithType<unknown>("admin"),
  gatherPosts: collectionRefWithType<GatherPost>("gatherPosts"),
  userGatherJunction:
    collectionRefWithType<UserGatherJunction>("userGatherJunction"),
  challenge: collectionRefWithType<Challenge>("challenges"),
  junctionProfileChallenge: collectionRefWithType<JunctionProfileChallenge>(
    "junction_profile_challenge"
  ),
  posts: collectionRefWithType<Post>("posts"),
  userFollowers: collectionRefWithType<UsersFollowers>("usersFollowers"),
  profiles: collectionRefWithType<Profile>("profiles"),
  profile: (userId: string) =>
    collectionRefWithType<Profile>("profiles").doc(userId),
  challengeFieldsToCompare: collectionRefWithType<ChallengeAdditionalData>(
    "challengesFieldsToCompare"
  ),
  discussionComments: collectionRefWithType<GatherPostComment>(
    "gatherDiscussionComments"
  ),
  comments: (postId: string) =>
    subCollectionRefWithType<Comment, Post>(postId, "comments", "posts"),
  likes: (postId: string) =>
    subCollectionRefWithType<Like, Post>(postId, "likes", "posts"),
  submissions: (eventId: string) =>
    subCollectionRefWithType<Submission, unknown>(
      eventId,
      "submissions",
      "dreamhack_submissions"
    ),
  teams: collectionRefWithType<Team>("teams"),
  userTeamJunction: collectionRefWithType<UserTeamJunction>("userTeamJunction"),
  teamsFollowers: collectionRefWithType<TeamFollowers>("teamsFollowers"),
  teamTournamentJunction: collectionRefWithType<TeamTournamentJunction>(
    "teamTournamentJunction"
  ),
  games: collectionRefWithType<Games>("games"),
  tournaments: collectionRefWithType<Tournament>("tournaments"),
  gathers: collectionRefWithType<Gather>("gathers"),
  news: collectionRefWithType<News>("news"),
  newsActivity: collectionRefWithType<NewsActivity>("newsActivity"),
  notifications: collectionRefWithType<Notification>("notifications"),
  winners1337: collectionRefWithType<any>("1337winners"),
  reports: subCollectionRefWithType<Report, null>(
    "reports",
    "reports",
    "admin"
  ),
  partners: {
    hp: {
      brands: collectionRefWithType<Brand>("retail_products")
        .doc("hp")
        .collection("brands"),
      products: collectionRefWithType<Training>("retail_products")
        .doc("hp")
        .collection("products"),
    },
  },
};
// ---------------------------------------------------------------------
// For fetching single document
async function getDocs<T>(
  dataToBeFetched: firebase.firestore.CollectionReference<T>
) {
  const dataFetched = await dataToBeFetched.get();
  return dataFetched.docs.map(
    (x: firebase.firestore.QueryDocumentSnapshot<T>) => x.data()
  );
}

export const getDataFromQuery = {
  challenge: (query: firebase.firestore.CollectionReference<Challenge>) =>
    getDocs<Challenge>(query),
  profile: (query: firebase.firestore.CollectionReference<Profile>) =>
    getDocs<Profile>(query),
  posts: (query: firebase.firestore.CollectionReference<Post>) =>
    getDocs<Post>(query),
  userFollowers: (
    query: firebase.firestore.CollectionReference<UsersFollowers>
  ) => getDocs<UsersFollowers>(query),
  Junction_profile_challenge: (
    query: firebase.firestore.CollectionReference<JunctionProfileChallenge>
  ) => getDocs<JunctionProfileChallenge>(query),
  gathers: (query: firebase.firestore.CollectionReference<Gather>) =>
    getDocs<Gather>(query),
  news: (query: firebase.firestore.CollectionReference<News>) =>
    getDocs<News>(query),
};

const collectionDocSnapshot = <Type>(
  docId: string,
  docRef: firebase.firestore.CollectionReference<Type>
) => {
  return docRef.doc(docId).get();
};

const commentDocSnapshot = (postId: string, docId: string) => {
  return collectionRef.comments(postId).doc(docId).get();
};
export const collectionDoc = {
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId challenge id
   * @returns Promise<firebase.firestore.DocumentSnapshot<Challenge>>
   */
  challenge: (docId: string) =>
    collectionDocSnapshot<Challenge>(docId, collectionRef.challenge),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId junction profile challenge id
   * @returns Promise<firebase.firestore.DocumentSnapshot<JunctionProfileChallenge>>
   */
  junctionProfileChallenge: (docId: string) =>
    collectionDocSnapshot<JunctionProfileChallenge>(
      docId,
      collectionRef.junctionProfileChallenge
    ),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId post id
   * @returns Promise<firebase.firestore.DocumentSnapshot<Post>>
   */
  posts: (docId: string) =>
    collectionDocSnapshot<Post>(docId, collectionRef.posts),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId user follower id
   * @returns Promise<firebase.firestore.DocumentSnapshot<UsersFollowers>>
   */
  userFollowers: (docId: string) =>
    collectionDocSnapshot<UsersFollowers>(docId, collectionRef.userFollowers),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId challenge field to compare id
   * @returns Promise<firebase.firestore.DocumentSnapshot<ChallengeAdditionalData>>
   */
  challengeFieldsToCompare: (docId: string) =>
    collectionDocSnapshot<ChallengeAdditionalData>(
      docId,
      collectionRef.challengeFieldsToCompare
    ),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId profile id
   * @returns Promise<firebase.firestore.DocumentSnapshot<Profiles>>
   */
  profiles: (docId: string) =>
    collectionDocSnapshot<Profile>(docId, collectionRef.profiles),
  /**
   * Returns the firebase document snapshot with dedicated type of the collection.
   * @param {string} docId comment id
   * @returns Promise<firebase.firestore.DocumentSnapshot<Comment>>
   */
  comments: (postId: string, docId: string) =>
    commentDocSnapshot(postId, docId),

  gather: (docId: string) =>
    collectionDocSnapshot<Gather>(docId, collectionRef.gathers),

  news: (docId: string) =>
    collectionDocSnapshot<News>(docId, collectionRef.news),

  newsActivity: (docId: string) =>
    collectionDocSnapshot<NewsActivity>(docId, collectionRef.newsActivity),
};
// ---------------------------------------------------------------------
// For fetching multiple docs when array of doc ids is provided

const getAllCollectionDocs = async <Type>(
  docIds: string[],
  collectionName: string
) => {
  const collectionRefOfAllIds: Promise<
    firebase.firestore.DocumentSnapshot<Type>
  >[] = [];
  for (const docId of docIds) {
    const doc = collectionRefWithType<Type>(collectionName).doc(docId).get();
    collectionRefOfAllIds.push(doc);
  }
  return await Promise.all(collectionRefOfAllIds);
};

const getAllCommentsBasedOnCommentIds = async (
  postId: string,
  docIds: string
) => {
  const collectionRefOfAllIds: Promise<
    firebase.firestore.DocumentSnapshot<Comment>
  >[] = [];
  for (const docId of docIds) {
    const doc = collectionDoc.comments(postId, docId);
    collectionRefOfAllIds.push(doc);
  }
  return await Promise.all(collectionRefOfAllIds);
};

export const getAllCollectionDocsBasedOfDocIds = {
  challenge: (docIds: string[]) =>
    getAllCollectionDocs<Challenge>(docIds, "challenges"),
  junctionProfileChallenge: (docIds: string[]) =>
    getAllCollectionDocs<JunctionProfileChallenge>(
      docIds,
      "junction_profile_challenge"
    ),
  posts: (docIds: string[]) => getAllCollectionDocs<Post>(docIds, "posts"),
  userFollowers: (docIds: string[]) =>
    getAllCollectionDocs<UsersFollowers>(docIds, "usersFollowers"),
  profiles: (docIds: string[]) =>
    getAllCollectionDocs<Profile>(docIds, "profiles"),
  comments: getAllCommentsBasedOnCommentIds,
};

// -----------------------------------------------------------------
