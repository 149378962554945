import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { generateRandomString } from "../helperFunctions/generateRandomString";
import { SignupLogitechForm } from "./Signup.Logitech.form";
import { handleFirebaseUserWithPasswordAndEmail } from "@/utils/sharedHelperFunctions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { siteRoutes } from "../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { toast, ToastContainer } from "react-toastify";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { SignupLogitechLoader } from "./Signup.Logitech.loader";

export const SignupLogitech: FC<{ customEvent: () => Promise<void> }> = ({
  customEvent,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();

  // Auth logic
  const [
    responseUserAuth,
    _loadingUserAuth,
    _errorUserAuth,
    sendVerificationEmail,
  ] = useCloudFunctionCall<AuthenticationResponse>(
    "userAuthentication",
    undefined
  );

  const [response, _loading, _error, createDoc] = useCloudFunctionCall<{
    name: string;
    email: string;
    username: string;
    dob: string;
    storeName: string;
    terms: boolean;
  }>("addNewSignupToSheet", undefined);

  const setUserProfile = useCurrentUserProfile((s) => s.setUser);

  const [googleSheetinfo, setGoogleSheetinfo] = useState<{
    email: string;
    store: string;
    fullName: string;
    userName: string;
    dateOfBirth: string;
    terms: boolean;
    password: string;
  }>({
    email: slug ? decodeURI(slug) : "",
    store: "Spillexpo",
    fullName: "",
    userName: "",
    dateOfBirth: "",
    terms: false,
    password: "",
  });

  const [error, setError] = useState<Error>();
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(googleSheetinfo.email);
  const baseAuthenticationProps = {
    method: "email",
    emailAddress: googleSheetinfo.email,
    platform: brand.brandValue,
  } as AuthenticationProps;

  const setFieldValue = async (targetName: string, value: any) => {
    return setGoogleSheetinfo((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Page Document Title
  useDocumentTitle("Logitech McLaren G Challenge Race Days EU");

  const handleCreateGoogleDoc = async () => {
    if (!googleSheetinfo.email) {
      setError(new Error("Email is required"));
      return;
    }

    if (!isEmailValid) {
      setError(new Error("Email is not a valid format!"));
      return;
    }

    if (!googleSheetinfo.fullName) {
      setError(new Error("First and last name is required"));
      return;
    }
    if (!googleSheetinfo.userName) {
      setError(new Error("Username is required"));
      return;
    }
    if (!googleSheetinfo.dateOfBirth) {
      setError(new Error("Date of Birth is required"));
      return;
    }
    if (!googleSheetinfo.terms) {
      setError(new Error("You must accept the terms and conditions"));
      return;
    }

    try {
      setLoading(true);
      const password = generateRandomString();

      await sendVerificationEmail({
        ...baseAuthenticationProps,
        action: "signup",
        password: password,
      }).then((res) => {
        console.log("DONE sending code");
      });

      const res = await handleFirebaseUserWithPasswordAndEmail(
        "login", // the firebase user is made in the "sendVerificationEmail", this if for user DOCUMENT
        true,
        brand.brandValue.toLowerCase() + "_" + googleSheetinfo.email,
        password,
        googleSheetinfo.userName,
        setUserProfile,
        googleSheetinfo.fullName,
        googleSheetinfo.terms,
        {
          dob: googleSheetinfo.dateOfBirth,
        }
      );

      if (res) {
        await createDoc({
          name: googleSheetinfo.fullName,
          email: googleSheetinfo.email,
          username: googleSheetinfo.userName,
          dob: googleSheetinfo.dateOfBirth,
          storeName: googleSheetinfo.store,
          terms: googleSheetinfo.terms,
        });

        if (customEvent) {
          return customEvent();
        } else {
          setLoading(false);
          return navigate("/home");
        }
      }
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (loading) {
    return <SignupLogitechLoader />;
  }

  return (
    <>
      <SignupLogitechForm
        setFieldValue={setFieldValue}
        googleSheetinfo={googleSheetinfo}
        handleCreateGoogleDoc={handleCreateGoogleDoc}
        _loading={_loading}
      />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
        limit={3}
      />
    </>
  );
};
