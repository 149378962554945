const TRANSLATIONS_PL = {
  logitech: {
    signup: {
      headline: "Witamy na Logitech McLaren G Challenge Race Days!",
      description:
        "Wykręć swój najlepszy czas okrążenia w grze Forza Motorsport i rywalizuj z innymi kierowcami w swoim sklepie i regionie. Sprawdzaj wyniki co tydzień na stronie www.logitechgchallenge.com/race-days oraz na Discordzie Logitech G.",
    },
    input: {
      headline: "Zarejestruj się, aby rozpocząć",
      email: {
        label: "E-mail",
        placeholder: "jan.kowalski@email.com",
      },
      store: {
        label: "Sklep",
        placeholder: "Enter your store",
      },
      fullName: {
        label: "Imię i nazwisko",
        placeholder: "Jan Kowalski",
      },
      userName: {
        label: "Nazwa użytkownika",
        placeholder: "jkowalksi",
      },
      dateOfBirth: {
        label: "Data urodzenia",
        placeholder: "",
      },
      terms: {
        headline: "Warunki",
        label: "Akceptuję powyższe",
        description:
          "Wszyscy uczestnicy muszą przeczytać i zrozumieć Regulamin przed przesłaniem zgłoszenia do konkursu.",
        descriptionWithLink:
          "Wszyscy uczestnicy muszą przeczytać i zrozumieć <1>Regulamin</1> przed przesłaniem zgłoszenia do konkursu.",
      },
      submit: {
        label: "Wyślij",
      },
    },
  },
};

export default TRANSLATIONS_PL;
