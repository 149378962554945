import BaseUserAvatar from "@/components/BaseUserAvatar";
import { getDiscordAuthURL } from "../helperFunctions/getDiscordAuthURL";
import { getTwitchAuthURL } from "../helperFunctions/getTwitchAuthURL";

type CustomSigninProps = {
  logo: string;
  backgroundColor: string;
  url: string;
  longText: string;
  shortText: string;
  type: string;
};

export const CustomSignInButton: React.FunctionComponent<CustomSigninProps> = ({
  logo,
  backgroundColor,
  url,
  longText,
  shortText,
  type,
}) => {
  const handleCustomButtonEvent = () => {
    localStorage.setItem("signUpMethod", shortText);
    if (type === "discord") {
      return (window.location.href = getDiscordAuthURL());
    }
    if (type === "twitch") {
      return (window.location.href = getTwitchAuthURL());
    }

    window.location.href = url;
  };

  return (
    <a
      onClick={() => {
        handleCustomButtonEvent();
      }}
      style={{ backgroundColor }}
      className={`btn ${type}`}
    >
      <figure>
        <BaseUserAvatar logoUrl={logo} userName="" />
      </figure>

      <span>
        <p>{longText}</p>
      </span>
    </a>
  );
};
