import { createToast } from "@/utils/toaster/createToast";
import ImageKit from "imagekit-javascript";
import { functions } from "../firebase/firebase.config";

export const _convertImageToImgIX = async (image: File, root?: string) => {
  const publicKey = "public_XxaVmNhQEC/UfawFZ8ySmSztogM=";
  const urlEndpoint = "https://ik.imagekit.io/gmng/iplay";

  const formatRootToImageKitRules = (root) => {
    // Imagekit folder name rules
    // No special characters like ! @ # $ % ^ & * ( ) + = { } [ ] | \ : ; " ' < > , ? /
    // No whitespaces
    const characterToReplace = "_";
    // Remove whitespace
    root = root?.trim();

    // Replace special characters with %
    root = root?.replaceAll(/[^a-zA-Z0-9/]/g, characterToReplace);

    // Replace double % with single %
    root = root?.replaceAll(/%+/g, characterToReplace);

    // Remove % from the beginning and end of the string
    root = root?.replaceAll(/^%+|%+$/g, "");

    return root;
  };

  const signature = (await functions("getSignatureKey")({
    imageURL: "",
  })) as {
    data: {
      token: string;
      expire: number;
      signature: string;
    };
  };

  if (!signature || !signature?.data || !signature?.data.signature) {
    createToast({
      type: "error",
      message: `Image failed to upload, missing credentials`,
    });
    return false;
  }

  // Initialize
  const imagekit = new ImageKit({
    publicKey,
    urlEndpoint,
  });

  let response;
  // Upload
  await imagekit
    .upload({
      file: image,
      fileName: image.name,
      tags: [],
      token: signature.data.token,
      signature: signature.data.signature,
      expire: signature.data.expire,
      folder: formatRootToImageKitRules(root),
    })
    .then(async (result) => {
      // Set responese if URL is present
      if (result.url) {
        // CHECK IF IMAGE IS APPROPIATE
        const isImageFlaggedExplicit = (await functions("checkImagesForNSFW")({
          imageURL: result.url,
          fileId: result.fileId,
        })) as {
          data: "passed" | "failed";
        };

        if (result.url && isImageFlaggedExplicit.data === "passed") {
          return (response = result.url);
        }

        // If image is failed and is explicite or NSFW, remove image from imagekit and replace image with placeholder
        if (isImageFlaggedExplicit.data === "failed") {
          createToast({
            type: "error",
            message: `The image uploaded was flagged, this could be due to being NSFW, having explicit content, or other reason.`,
          });
          return (response =
            "https://ik.imagekit.io/gmng/placeholder_sensitive.gif?updatedAt=1704807133701");
        }
      } else {
        if (!result.url) {
          createToast({
            type: "error",
            message: `Failed to upload image! please try again later.`,
          });
          return false;
        }
        return false;
      }
      return response;
    });

  if (response) {
    return response;
  } else {
    return false;
  }
};

// Reference
interface UploadResponse {
  fileId: string;
  name: string;
  url: string;
  thumbnailUrl: string;
  height: number;
  width: number;
  size: number;
  filePath: string;
  tags: string[];
  AITags: [
    {
      name: string;
      confidence: number;
      source: string;
    },
    /* ... more googleVision tags ... */
  ];
  versionInfo: {
    id: string;
    name: string;
  };
  isPrivateFile: boolean;
  customCoordinates: null;
  customMetadata: {
    brand: string;
    color: string;
  };
  embeddedMetadata: {
    Title: string;
    Description: string;
    State: string;
    Copyright: string;
  };
  extensionStatus: any;
  fileType: string;
}
