import React, { useEffect, useRef, useState } from "react";
import { getCameraState } from "../../helperfunctions/getCameraState";
import { Button } from "./../../../../../src/components/Button/index";
import {
  handlePrizeCollected,
  hasPrizeBeenCollected,
} from "../../helperfunctions/handlePrizeCollected";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
export const TreasureHuntButtons = ({
  openCamera,
  setOpenCamera,
  hasAllCodes,
  setIsCameraConnected,
  onPaddingChange,
}: {
  openCamera: any;
  setOpenCamera: any;
  hasAllCodes: boolean;
  setIsCameraConnected: any;
  onPaddingChange: (padding: number) => void;
}) => {
  const currentUser = useCurrentUserProfile((s) => s.user);
  const prizeCollected = hasPrizeBeenCollected(currentUser);
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      const buttonHeight = buttonRef.current.offsetHeight;
      onPaddingChange(buttonHeight);
    }
  }, []);

  const checkCameraState = async () => {
    const hasCamera = await getCameraState();
    if (hasCamera) {
      setIsCameraConnected(hasCamera);
      setOpenCamera(hasCamera);
    }
  };

  if (openCamera) {
    return (
      <Button
        variant="primary"
        onClickAction={() => {
          setOpenCamera(false);
        }}
      >
        Close camera
      </Button>
    );
  }

  if (!openCamera && !hasAllCodes && !prizeCollected) {
    return (
      <Button
        reff={buttonRef}
        variant="primary"
        onClickAction={async () => {
          checkCameraState();
        }}
      >
        Scan the qr code
      </Button>
    );
  }

  if (!openCamera && hasAllCodes && !prizeCollected) {
    return (
      <Button
        variant="primary"
        onClickAction={() => {
          handlePrizeCollected(currentUser);
        }}
      >
        I have collected the prize
      </Button>
    );
  }

  if (!openCamera && prizeCollected) {
    return (
      <Button variant="secondary" disabled>
        I have collected the prize
      </Button>
    );
  }
};
