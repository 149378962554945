import { useEffect, useState } from "react";
import firebase from "firebase/app";
import { days, fullDays } from "./../../../../src/utils/constants/weekDays";

type LeaderboardItemTypes = {
  uid: string;
  attempts: number;
  userName: string;
  winningDate: any;
  score: number;
  winningDay: string;
};

export const useLeaderboardData = ({ currentUser }) => {
  const [leaderboarData, setLeaderboardData] =
    useState<LeaderboardItemTypes[]>();
  const [attemptCount, setAttemptCount] = useState<number>(0);
  const [success, setSuccess] = useState<boolean>(false);

  const data = {
    all: leaderboarData,
    hasScore1337: leaderboarData?.filter((user) => user.score === 1337),
    honorableMentions: leaderboarData?.filter((user) => user.score !== 1337),
  };

  const getAttempsLeaderboard = async () => {
    firebase
      .firestore()
      .collection("1337winners")
      .limit(25)
      .onSnapshot((snapshot) => {
        const formattedData = snapshot.docs.map((doc) => {
          const today = new Date(doc.data().winningDate.seconds * 1000);
          const winningDay = fullDays[days[today.getDay()]];
          if (doc?.data()) {
            if (doc.id === `${currentUser.userName}_${currentUser.uid}`) {
              setAttemptCount(doc.data().attempts);
              setSuccess(true);
            }

            return {
              ...doc.data(),
              winningDay: winningDay,
            } as LeaderboardItemTypes;
          }
        });

        // if (formattedData.length !== 0) {
        setLeaderboardData(
          formattedData.sort((a, b) => a.attempts - b.attempts) || []
        );
        // }
      });
  };

  useEffect(() => {
    getAttempsLeaderboard();
  }, []);

  return { data, attemptCount, success } as const;
};
