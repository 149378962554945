export const generateRandomString = () => {
  const digits = "0123456789";
  const lowerCase = "abcdefghijklmnopqrstuvwxyz";
  const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialCharacters = "@#$%^&+=!";

  const getRandomCharacter = (characters) =>
    characters[Math.floor(Math.random() * characters.length)];

  let stringBuilder = [
    getRandomCharacter(digits),
    getRandomCharacter(lowerCase),
    getRandomCharacter(upperCase),
    getRandomCharacter(specialCharacters),
  ];

  const allCharacters = digits + lowerCase + upperCase + specialCharacters;

  // Generate string up to 15 charaacters long
  while (stringBuilder.length < 15) {
    stringBuilder.push(getRandomCharacter(allCharacters));
  }

  return stringBuilder.join("");
};
