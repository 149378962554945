import { Spinner } from "@/components";
import React, { FC, useEffect, useState } from "react";
import "./styles/multiplayer-gamer-redirect.scss";
import { createQuickAccountUser } from "@/utils/sharedHelperFunctions";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { auth } from "@/api/firebase/firebase.config";

export const MultiplayerBrowserGameRedirect: FC<{}> = ({}) => {
  const [loadingStatus, setLoadingStatus] = useState<string>("Loading");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setProfile = useCurrentUserProfile((s) => s.setUser);
  const currentUser = useCurrentUserProfile((s) => s.user);

  // Redirect
  const handleRedirect = (res) => {
    setIsLoading(true);

    const user = res || currentUser;
    if (user) {
      setLoadingStatus("Redirecting to multiplayer browser game");
      setIsLoading(false);
      return (window.location.href = `https://browser-game-remote.web.app/${user.uid}`);
    }
  };

  // Anno login
  const handleSignAnonymously = async () => {
    setIsLoading(true);
    setLoadingStatus("Creating a quick account");
    await createQuickAccountUser(setProfile).then((res) => {
      return handleRedirect(res);
    });
  };

  // Start flow
  const handleNextStep = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if ((currentUser && currentUser.uid) || (auth && auth.currentUser?.uid)) {
      return handleRedirect(null);
    }

    // if (currentUser === null || !currentUser || !currentUser.uid) {
    // return handleSignAnonymously();
    // }

    if (auth && !auth?.currentUser?.uid) {
      return handleSignAnonymously();
    }
  };

  useEffect(() => {
    if (!isLoading && loadingStatus === "Loading" && auth) {
      handleNextStep();
    }
  }, [auth.currentUser]);

  return (
    <div className="spacecoins-redirect">
      <div>
        <h1>{loadingStatus}</h1>
        <Spinner />
      </div>
    </div>
  );
};
