import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as BurgerIcon } from "../assets/burger.svg";
import { ReactComponent as MenuIcon } from "../../../../src/assets/icons/menu.svg";
import { Image } from "react-bootstrap";
import { Button, ErrorFallback } from "@/components";
import { ReactComponent as IconUser } from "../assets/user.svg";
import { ReactComponent as IconUserBG } from "../../../../src/assets/icons/user.svg";
import NavigationBarAvatar from "./NavigationBarAvatar";
import NotificationsDropdown from "@/features/notifications/components/bell/Notifications.fetcher";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { useMemo } from "react";
import { SideMenuMobile } from "../../sideNavigation/components/SideMenu.mobile.fetcher";
import { brand } from "../../../utils/brandVariables/brandVariables";
import NavbarSearchInputWrapper from "@/components/SearchInput/NavbarSearchInputWrapper";
import { useScrollBlock } from "@/hooks/useScrollBlock";
import { partnerRoute } from "../../../utils/brandVariables/siteRoutes/partnerRoutes";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { clientAtom } from "@/utils/atoms/atomClient";
import { findPartner } from "@/utils/constants/findPartner";

export const headerHeight = atom(0);

export default function NavigationBarUi(props: any) {
  const [fullSearch, setFullSearch] = useState(false);
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [blockScroll, allowScroll] = useScrollBlock();
  const location = useLocation();
  const currentClient = useAtomValue(clientAtom);
  const headerRef = useSetAtom(headerHeight);

  const NotificationRender = () =>
    useMemo(() => {
      try {
        return (
          <>
            {props.profile ? (
              <NotificationsDropdown currentUser={props.currentUser} />
            ) : (
              ""
            )}
          </>
        );
      } catch {
        return <ErrorFallback></ErrorFallback>;
      }
    }, [props.currentUser]);

  useEffect(() => {
    allowScroll();

    if (openOffcanvas) {
      blockScroll();
    } else {
      allowScroll();
    }
  }, [openOffcanvas]);

  useEffect(() => {
    let scrollpos = window.scrollY;
    const header = document.querySelector("header");
    const headerHeight = header.offsetHeight;
    headerRef(headerHeight);

    const AddClassOnScroll = () => header.classList.add("header--scrolled");
    const RemoveClassOnScroll = () =>
      header.classList.remove("header--scrolled");

    window.addEventListener("scroll", function () {
      scrollpos = window.scrollY;

      if (scrollpos >= headerHeight) {
        AddClassOnScroll();
      } else {
        RemoveClassOnScroll();
      }
    });
  });

  const isBestGamer = brand.brandValue === "BESTGAMER";
  const isElgiganten = brand.brandValue === "ELGIGANTEN";
  const isLogitech = brand.brandValue === "LOGITECH";

  return (
    <>
      <SideMenuMobile
        show={openOffcanvas}
        closeWindow={setOpenOffcanvas}
        isAuth={!!currentUser}
      />

      <header className="header">
        <div className="header__container container">
          <div className={`header__main ${fullSearch ? "search-open" : ""}`}>
            <div className="header__input">
              <div className="header__profile">
                {isElgiganten || isBestGamer ? (
                  findPartner(currentClient) ? (
                    <Link to={`/home`} className="btn btn-link">
                      <Image src={brand.logos.small} height={18} width={18} />
                    </Link>
                  ) : (
                    <Link to={`/profile`} className="btn btn-link btn-profile">
                      {isBestGamer ? <IconUserBG /> : <IconUser />}
                    </Link>
                  )
                ) : (
                  <Link to={`/profile`} className="btn btn-link">
                    <IconUser />
                  </Link>
                )}
              </div>

              {brand.hasFeature.search && (
                <div className="header__search">
                  {props.currentUser && (
                    <NavbarSearchInputWrapper
                      setFullParentSearch={setFullSearch}
                    />
                  )}
                </div>
              )}

              {(isElgiganten || isBestGamer) && (
                <div className="header__partner">
                  {findPartner(currentClient) && (
                    <Image src={findPartner(currentClient).logo.large} />
                  )}
                </div>
              )}
            </div>

            {!fullSearch && (
              <div className="header__logo">
                {isLogitech ? (
                  <Link to="/home">
                    <Image src={brand.logos.small} />
                  </Link>
                ) : findPartner(currentClient) ? (
                  <Link to={findPartner(currentClient).route}>
                    <Image src={findPartner(currentClient).logo.small} />
                  </Link>
                ) : (
                  <Link to="/home">
                    <Image src={brand.logos.small} />
                  </Link>
                )}
              </div>
            )}

            <div className="header__actions">
              {props.profile && (
                <NavigationBarAvatar profile={props.profile} styleProps="" />
              )}
              {brand.hasFeature.notifications && <NotificationRender />}
              <Button
                variant="link"
                onClickAction={() => setOpenOffcanvas(true)}
                customStyles="btn-menu header__menu"
              >
                {isBestGamer ? <MenuIcon /> : <BurgerIcon />}
              </Button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
