import { useNavigate, useParams } from "react-router-dom";
import useSingleChallenge from "../hooks/useSingleChallenge";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { clientAtom } from "@/utils/atoms/atomClient";
import { useAtom } from "jotai";
import useProfile from "../hooks/useProfile";
import SingleChallengeLoader from "@/components/skeletonLoaders/singleChallengeLoader/SingleChallengeLoader";
import { PageDoesntExist } from "@/pageDoesntExist";
import { SingleChallengeEventUi } from "../components/single/SingleChallengeEvent.ui";
import { useEffect, useState } from "react";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { partnerRoute } from "../../../../branchAndBrandSpecific/utils/brandVariables/siteRoutes/partnerRoutes";
import { singleChallengeProps } from "../utils/singleChallengeProps";
import useProfileChallengeJunction from "../hooks/useProfileChallengeJunction";

export default function SingleChallengeEventPage() {
  const { slug } = useParams();
  const { challenge, loading, error } = useSingleChallenge(slug);
  const currentUser = useCurrentUserProfile((s) => s.user);
  const [currentClient, setCurrentClient] = useAtom(clientAtom);
  const { profile } = useProfile(challenge?.createdBy);
  const [ChallengeCreator, setChallengeCreator] = useState<any>(null);
  // const { challengeUser } = useProfileChallengeJunction(challenge?.slug, currentUser?.uid);

  useEffect(() => {
    if (challenge && challenge.client) {
      setCurrentClient(challenge.client);
    }
  }, [challenge, currentClient, setCurrentClient]);

  useEffect(() => {
    setChallengeCreator(profile);
  }, [challenge, profile]);

  // Set different themes for individual partner pages
  useEffect(() => {
    if (brand.brandTheme === "elgiganten") {
      const partnerKeys = Object.keys(partnerRoute);

      partnerKeys.forEach((key) => {
        const partner = partnerRoute[key].partner;

        if (challenge?.client === partner) {
          document.documentElement.className = partnerRoute[key].theme;
          setCurrentClient(partner);
        }
      });
    }
  }, [brand.brandTheme, partnerRoute, challenge]);

  if (loading) {
    return <SingleChallengeLoader />;
  }

  if (error) {
    return <PageDoesntExist />;
  }

  return (
    <SingleChallengeEventUi
      data={challenge}
      creator={profile}
      client={currentClient}
      currentUser={currentUser}
    />
  );
}
