const TRANSLATIONS_NO = {
  leet: {
    headline: "Test presisjonen din",
    objective: "Kan du treffe {{objective}} ved å klikke på knappen?",
    objective1: "Kan du treffe",
    objective2: "ved å klikke på knappen?",
    totalAttempts: "Totalt Antall Forsøk",
    start: "Start",
    stop: "Stopp",
    youWon: "Du vant",
    congrats: "Gratulerer! Godt gjort!",
    nextStep: "Vis dette til en Elkjøp-ansatt i butikken",
    leaderboard: {
      headline: "Vinnere",
      date: "Dato",
      username: "Brukernavn",
      attempts: "Forsøk",
    },
    honorable: {
      headline: "Hederlige omtaler",
      none: "Ingen hederlige omtaler ennå",
      winnersOnly: "Bare vinnere!",
      noWinners: "Det er ingen vinnere for øyeblikket.",
    },
    toast: {
      success: "Du har blitt lagt til på leaderboardet",
    },
  },

  logitech: {
    signup: {
      headline: "Velkommen til Logitech McLaren G Challenge Race Days!",
      description:
        "Sett din rundetid i Forza Motorsport og konkurrer mot de andre sjåførene i butikken din og regionen. Sjekk resultater ukentlig på www.logitechgchallenge.com/race-days og Logitech G Discord.",
    },
    input: {
      headline: "Registrer deg for å starte",
      email: {
        label: "E-post",
        placeholder: "ola.nordmann@email.com",
      },
      store: {
        label: "Butikk",
        placeholder: "Skriv inn butikken din",
      },
      fullName: {
        label: "Navn, fornavn og etternavn",
        placeholder: "Ola Nordmann",
      },
      userName: {
        label: "Brukernavn",
        placeholder: "Skriv inn brukernavnet ditt",
      },
      dateOfBirth: {
        label: "Fødselsdato",
        placeholder: "Skriv inn fødselsdatoen din",
      },
      terms: {
        headline: "Vilkår og betingelser",
        label: "Jeg godtar det ovenfor",
        description:
          "Alle deltakere må lese og forstå vilkårene og betingelsene før de sender inn et konkurransebidrag.",
        descriptionWithLink:
          "Alle deltakere må lese og forstå <1>vilkårene og betingelsene</1> før de sender inn et konkurransebidrag.",
      },
      submit: {
        label: "Send inn",
      },
    },
  },
};

export default TRANSLATIONS_NO;
