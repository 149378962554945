import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import battlenetLogo from "../assets/auth-bnet.svg";
import steamLogo from "../assets/auth-steam.svg";
import discordLogo from "../assets/auth-discord.svg";
import twitchLogo from "../assets/auth-twitch.svg";

export const auhtOptions = [
  brand.hasAuth.steam ? [steamLogo, "#2D2E33", "steam", "STEAM", "STEAM"] : [],
  brand.hasAuth.bnet
    ? [battlenetLogo, "#2D2E33", "bnet", "Battle.net", "Battle.net"]
    : [],
  brand.hasAuth.discord
    ? [discordLogo, "", "Discord", "discord", "discord"]
    : [],
  brand.hasAuth.twitch ? [twitchLogo, "", "Twitch", "twitch", "twitch"] : [],
];
