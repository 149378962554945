export const Challenge1337PageLeaderboardMentions = ({ data, hasWinners }) => {
  return (
    <div className="leet-challenge__honorable">
      <div className="honorable">
        <h2>Honorable mentions</h2>
        <div className="honorable__list">
          {data?.length === 0 && (
            <div className="honorable__item">
              No honorable mentions yet.
              {hasWinners && " Only winners!"}
            </div>
          )}
          {data &&
            data.map((doc, index) => {
              return (
                <div className="honorable__item" key={index}>
                  {doc.userName} ({doc.score})
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};
