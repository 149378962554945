import { Button, ErrorFallback, Spinner } from "@/components";
import { siteRoutes } from "../../../../../branchAndBrandSpecific/routes/siteRoutesList";
import { createToast } from "@/utils/toaster/createToast";
import { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate, useParams } from "react-router-dom";

export const EventQrCode = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isCameraConnected, setIsCameraConnected] = useState<boolean>(true);

  const getCameraState = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter((device) => device.kind === "videoinput");
    if (cameras.length === 0) {
      setIsCameraConnected(false);
      setIsLoading(false);
      createToast({
        type: "info",
        message: "No camera found",
      });
    } else {
      setIsCameraConnected(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCameraState();
  }, []);

  const handleResult = async (result: any) => {
    try {
      if (result && result.text) {
        const formattedSlug = result.text.split("/").at(-1);
        navigate(`${siteRoutes["scanQrCodeAndSlug"]}${formattedSlug}`);
      }
    } catch (e) {
      createToast({
        type: "error",
        message: "Failed to connect!",
      });
    }
  };

  return (
    <>
      {isCameraConnected && (
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result) => handleResult(result)}
        />
      )}
      {!isCameraConnected && (
        <>
          <ErrorFallback>
            <h2>
              No camera found, please make sure a camera is connected and or the
              correct permissions have been given
            </h2>
            <Button
              variant="primary"
              onClickAction={() => {
                setIsLoading(true);
                getCameraState();
              }}
            >
              {isLoading ? <Spinner /> : "Check for camera again"}
            </Button>
            {/* <Button variant="primary">To</Button> */}
          </ErrorFallback>
        </>
      )}
    </>
  );
};
