const TRANSLATIONS_DE = {
  logitech: {
    signup: {
      headline: "Willkommen zu den Logitech McLaren G Challenge Race Days!",
      description:
        "Stellen Sie Ihre Rundenzeit in Forza Motorsport ein und treten Sie gegen die anderen Fahrer in Ihrem Geschäft und Ihrer Region an. Überprüfen Sie die Ergebnisse wöchentlich unter www.logitechgchallenge.com/race-days und im Logitech G Discord.",
    },
    input: {
      headline: "Melden Sie sich an, um zu starten",
      email: {
        label: "E-mail",
        placeholder: "max.mustermann@email.com",
      },
      store: {
        label: "Speichern",
        placeholder: "",
      },
      fullName: {
        label: "Name Vorname Nachname",
        placeholder: "Max Mustermann",
      },
      userName: {
        label: "Benutzername",
        placeholder: "mmustermann",
      },
      dateOfBirth: {
        label: "Geburtsdatum",
        placeholder: "Enter your date of birth",
      },
      terms: {
        headline: "Teilnahmebedingungen",
        label: "Ich akzeptiere das Obige",
        description:
          "Alle Teilnehmer müssen die Allgemeinen Geschäftsbedingungen lesen und verstehen, bevor sie einen Wettbewerbsbeitrag einreichen.",
        descriptionWithLink:
          "Alle Teilnehmer müssen die Allgemeinen  <1>Geschäftsbedingungen</1> lesen und verstehen, bevor sie einen Wettbewerbsbeitrag einreichen.",
      },
      submit: {
        label: "Einreichen",
      },
    },
  },
};

export default TRANSLATIONS_DE;
