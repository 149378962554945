import React, { useState, useEffect, FC } from "react";
import { useLocation } from "react-router-dom";
import { useCurrentUserProfile } from "@/store/auth/userProfile.store";
import { SettingsProfileImage } from "./pages/profileImage/SettingsProfileImage";
import { GeneralSettings } from "./pages/general/SettingsGeneral";
import { SettingsAccounts } from "./pages/accounts/SettingsAccounts.fetcher";
import { ThemeSwitcher } from "./pages/themes/ThemeSwitcher";
import { refreshUser } from "@/utils/sharedHelperFunctions";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { ReactComponent as BreadCrumbArrow } from "../../../../assets/actions/chevron-right.svg";
import { Button } from "@/components";
import { SettingsTabs } from "../SettingsTabs";
import { SettingsPreferences } from "./pages/preferences/SettingsPreferences";
import PreferencesData from "./pages/preferences/PreferencesData";
import { ProfileSettingsTabData } from "../ProfileSettingsTabData";
import { ChangePasswordPage } from "./pages/password/ChangePasswordPage";
import i18next from "i18next";

export const ProfileSettings: FC = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState(
    localStorage.getItem("lastCurrentTab") ||
      i18next.t("settings.tabs.yourInfo")
  );

  useEffect(() => {
    setCurrentTab(i18next.t("settings.tabs.yourInfo"));
  }, [i18next.language]);
  const [currentChildTab, setCurrentChildTab] = useState("");
  const [mobileUi, setMobileUi] = useState(false);

  const currentUser = useCurrentUserProfile((s) => s.user);
  const setUserProfile = useCurrentUserProfile((s) => s.setUser);
  const location = useLocation();

  const prefTabsArray = Object.values(PreferencesData);
  const prefTabsTitle = prefTabsArray.find((tab) => tab.title === currentTab)
    ?.title;
  const activePreferenceTab = prefTabsArray.filter(
    (pref) => pref.title === currentChildTab
  )[0]?.inputs;

  const isDiscordUser = currentUser?.authenticationMethod === "discord";
  const isTwitchUser = currentUser?.authenticationMethod === "twitch";
  const isNotDiscordOrTwitchUser = !isDiscordUser && !isTwitchUser;

  // if hasFeature is false, the set tab will be removed completely and become unaccessible
  const settingsTabs: { [key: string]: JSX.Element | undefined } = {
    [i18next.t("settings.tabs.yourInfo")]: (
      <GeneralSettings setCurrentTab={setCurrentTab} />
    ),
    [i18next.t("settings.tabs.profileImage")]: isNotDiscordOrTwitchUser && (
      <SettingsProfileImage />
    ),
    Connections: brand.hasFeature["connections"] && <SettingsAccounts />,
    [i18next.t("settings.tabs.themes")]: brand.hasFeature["themes"] && (
      <ThemeSwitcher />
    ),
    [i18next.t("settings.tabs.preferences")]: brand.hasFeature[
      "preferences"
    ] && (
      <SettingsPreferences
        setCurrentTab={setCurrentTab}
        setCurrentChildTab={setCurrentChildTab}
      />
    ),
    [i18next.t("settings.tabs.changePassword")]: brand.hasFeature[
      "changePassword"
    ] && <ChangePasswordPage />,
  };

  const tabs = Object.entries(settingsTabs).map(([key, value]) => {
    if (value) {
      return key;
    }
  });

  useEffect(() => {
    refreshUser(setUserProfile, currentUser);
  }, []);

  useEffect(() => {
    if (currentTab && !mobileUi) {
      localStorage.setItem("lastCurrentTab", currentTab);
    }
    if (mobileUi) {
      localStorage.removeItem("lastCurrentTab");
    }
  }, [currentTab]);

  useEffect(() => {
    if (location.state?.from === "sign-up-step-4") {
      setCurrentTab("Connections");
    }
    if (location.state?.to === "Connections") {
      setCurrentTab("Connections");
    }
    if (location.state?.from === "home") {
      setCurrentTab("Connections");
    }
  }, [location.state?.from]);

  function handleResize() {
    if (window.innerWidth < 576) {
      localStorage.removeItem("lastCurrentTab");
      setMobileUi(true);
    } else {
      setMobileUi(false);
    }
  }

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobileUi]);

  return (
    <div className="settings">
      <div className="tabs">
        {/* If screen width is bigger than 576px the tabs appear on the left panel */}
        {!mobileUi && (
          <SettingsTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setCurrentChildTab={setCurrentChildTab}
            title={i18next.t("settings.tabs.language")}
            tabs={tabs}
          />
        )}

        {/* If screen width is less than 576px and the current tab is "Your information" then the mobile version will render */}
        {currentTab === i18next.t("settings.tabs.yourInfo") && mobileUi && (
          <SettingsTabs
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            setCurrentChildTab={setCurrentChildTab}
            title={i18next.t("settings.tabs.otherSettings")}
            tabs={tabs.slice(1)}
            mobileUi={mobileUi}
          />
        )}
      </div>

      <div className="content">
        <div className="content__breadcrumbs content__breadcrumbs--pc">
          {currentChildTab ? (
            <>
              <Button
                variant=""
                onClickAction={() => {
                  setCurrentChildTab("");
                  setCurrentTab(i18next.t("settings.tabs.preferences"));
                }}
              >
                <h4>{i18next.t("settings.tabs.preferences")}</h4>
                <BreadCrumbArrow />
              </Button>
              <h4> {currentChildTab}</h4>
            </>
          ) : (
            <h4>{currentTab}</h4>
          )}
        </div>

        <div className="content__breadcrumbs content__breadcrumbs--mobile">
          {currentTab !== i18next.t("settings.tabs.yourInfo") && (
            <>
              <Button
                variant=""
                onClickAction={() => {
                  setCurrentChildTab("");
                  setCurrentTab(i18next.t("settings.tabs.yourInfo"));
                }}
              >
                <h4>{i18next.t("settings.tabs.yourInfo")}</h4>
                {currentTab && <BreadCrumbArrow />}
              </Button>
              <Button
                variant=""
                onClickAction={() => {
                  if (currentTab === "Preferences") {
                    setCurrentChildTab("");
                  }
                }}
              >
                <h4>{currentTab}</h4>
                {currentChildTab && <BreadCrumbArrow />}
              </Button>

              {currentChildTab && (
                <>
                  <h4> {currentChildTab}</h4>
                </>
              )}
            </>
          )}
          {currentTab === i18next.t("settings.tabs.yourInfo") && (
            <h4>{currentTab}</h4>
          )}
        </div>

        <div
          className={`content__details ${currentTab
            .toLocaleLowerCase()
            .replace(/\s+/g, "-")}`}
        >
          <ProfileSettingsTabData
            setCurrentChildTab={setCurrentChildTab}
            settingsTabs={settingsTabs}
            currentTab={currentTab}
            activePreferenceTab={activePreferenceTab}
            currentChildTab={currentChildTab}
          />
        </div>
      </div>
    </div>
  );
};
