import { Profile } from "@/models/database/profiles/profile.model";
import { FC, useEffect, useState } from "react";
import {
  ChallengeCreatorProfile,
  singleChallengeAltProps,
} from "../../utils/singleChallengeProps";
import { SingleChallengeImage } from "./SingleChallengeImage";
import { EventSingleChallengeActions } from "./EventSingleChallengeActions";
import useProfileChallengeJunction from "../../hooks/useProfileChallengeJunction";
import { Leaderboard } from "../leaderboard/Leaderboard";
import { CreatorTypesOptions } from "../../utils/CreatorTypes";
import ChallengeDisclaimer from "./ChallengeDisclaimer";
import { Highlights } from "@/components/Highlights";
import { Spinner } from "@/components";
import { brand } from "../../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import { BestgamerEventHead } from "../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventHead/BestgamerEventHead";
import { EventDetails } from "../../../../../branchAndBrandSpecific/components/bestgamer/bestgamerEventDetails";

export const SingleChallengeEventUi: FC<{
  data: singleChallengeAltProps;
  creator: ChallengeCreatorProfile;
  client: string;
  currentUser: Profile;
}> = ({ data, creator, client, currentUser }): JSX.Element => {
  const { challengeUser, loadingJunction, error } = useProfileChallengeJunction(
    data?.slug,
    currentUser.uid
  );
  const [isCurrUserParticipant, setIsCurrUserParticipant] =
    useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loadingJunction) {
      return;
    }

    if (!challengeUser) {
      setIsCurrUserParticipant(false);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    }

    if (challengeUser && challengeUser.status === "accepted") {
      setIsCurrUserParticipant(true);
    }
  }, [challengeUser]);

  if (!data) {
    return <Spinner />;
  }

  const getGameTypeForBestGamer = () => {
    if (data?.gameType && brand.brandValue === "BESTGAMER") {
      return data.gameType.includes("f1")
        ? "game-racing"
        : `game-${data.gameType}`;
    }
    return "";
  };

  return (
    <div className={`page page__challenge ${getGameTypeForBestGamer()}`}>
      <div className="activity">
        <EventSingleChallengeActions
          data={data}
          currentUser={currentUser}
          isCurrUserParticipant={isCurrUserParticipant}
          setIsCurrUserParticipant={setIsCurrUserParticipant}
          loading={loading}
          setLoading={setLoading}
        />

        <div className="activity__image">
          <SingleChallengeImage url={data.logoUrl} alt={data.name} />
        </div>

        <BestgamerEventHead
          title={data.name}
          startDate={data.startDate}
          endDate={data.endDate}
          brand={brand.brandValue}
        />

        <div className="activity__leaderboard">
          <Leaderboard
            slug={data.slug}
            color={
              CreatorTypesOptions[data.creatorType]
                ? CreatorTypesOptions[data.creatorType].activeColor
                : CreatorTypesOptions.Community.activeColor
            }
            status={data.status}
            client={data.client}
            game={data.game}
          />
        </div>

        <div className="activity__highlights">
          <Highlights game={data.game} />
        </div>

        <div className="activity__description">
          <EventDetails
            goal={data.goal}
            description={data.description}
            game={data.game}
          />
        </div>

        <div className="activity__disclaimer">
          <ChallengeDisclaimer game={data.gameType} />
        </div>
      </div>
    </div>
  );
};
