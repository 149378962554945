import UnAuthorizedLayout from "../../../../branchAndBrandSpecific/layout/UnAuthorizedLayout";
import { FC, useEffect, useState } from "react";
import { AuthCard } from "../components/AuthCard";
import { BaseTextInput } from "@/components/BaseTextInput/BaseTextInput";
import { Button, Spinner } from "@/components";
import {
  AuthenticationProps,
  AuthenticationResponse,
} from "../types/authenticationTypes";
import { brand } from "../../../../branchAndBrandSpecific/utils/brandVariables/brandVariables";
import useCloudFunctionCall from "@/hooks/useCloudFunctionCall";
import { createToast } from "@/utils/toaster/createToast";
import { useNavigate } from "react-router-dom";
import { auth } from "@/api/firebase/firebase.config";
import { FirebaseAuthMethods } from "../components/FirebaseAuthMethods";
import useDocumentTitle from "@/hooks/useDocumentTitle";
import i18next from "i18next";
import { useAtomValue, useSetAtom } from "jotai";
import { authCredentialsAtom } from "@/utils/atoms/atomClient";
import { storageToRemove } from "@/utils/constants/localStorageKeys";

export const Login: FC<{}> = () => {
  const atomCredentials = useAtomValue(authCredentialsAtom);
  const setAtomCredentials = useSetAtom(authCredentialsAtom);
  const [userCredentials, setUserCredentials] = useState<{
    email: string;
    password: string;
  }>(
    atomCredentials || {
      email: "",
      password: "",
    }
  );
  const [googleAuthLoading, setGoogleAuthLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const baseAuthenticationProps = {
    method: "email",
    emailAddress: userCredentials.email,
    platform: brand.brandValue,
    // platform: "jbl",
  } as AuthenticationProps;

  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userCredentials.email);
  const setFieldValue = async (targetName: string, value: any) => {
    return setUserCredentials((prev: any) => ({
      ...prev,
      [targetName]: value,
    }));
  };

  // Auth logic
  const [response, _loading, _error, authenticate] =
    useCloudFunctionCall<AuthenticationResponse>(
      "userAuthentication",
      undefined
    );

  useEffect(() => {
    if (userCredentials.email) {
      if (response.status === "valid-email-address") {
        // Try to log in with a old account first
        auth
          .signInWithEmailAndPassword(response.email, userCredentials.password)
          .then((res) => {
            if (res.user && res.user.uid) {
              navigate("/home");
              setAtomCredentials({
                email: "",
                password: "",
              });
            }
            return setGoogleAuthLoading(false);
          })
          .catch(async (err) => {
            // if user tries to login with gmail account or wrong password
            if (err.code === "auth/wrong-password") {
              return createToast({
                type: "error",
                message: i18next.t("auth.toast.password.wrong"),
              });
            }
          });
      }

      if (response.status === "valid-credentials") {
        createToast({ type: "success", message: "Login successfull" });
        async () => {
          await auth
            .signInWithCustomToken(response.token)
            .then((res) => {
              if (res.user && res.user.uid) {
                setAtomCredentials({
                  email: "",
                  password: "",
                });
                // Remove any local data from localstorage
                storageToRemove.map((storageItem) => {
                  localStorage.removeItem(storageItem);
                });

                if (res.user) {
                  // navigate("/dashboard");
                }
              }

              return setGoogleAuthLoading(false);
            })
            .catch(async (err) => {
              // if user tries to login with gmail account or wrong password
              if (err.code === "auth/wrong-password") {
                return createToast({
                  type: "error",
                  message: i18next.t("auth.toast.password.wrong"),
                });
              }
            });
        };

        localStorage.removeItem("authEmailLocal");
        localStorage.removeItem("signUpMethod");
        return navigate("/dashboard");
      }

      if (response.status === "email-not-verified") {
        localStorage.setItem(
          "authEmailLocal",
          response.email || userCredentials.email
        );
        authenticate({
          ...baseAuthenticationProps,
          action: "resent-verification-code",
        });
        return navigate("/verify-email");
      }

      if (response.status === "missing-username") {
        localStorage.setItem("authEmailLocal", userCredentials.email);

        createToast({
          type: "error",
          message: i18next.t("auth.toast.profile.incomplete"),
        });
        navigate(response.redirectURL);
        return;
      }

      if (response.status !== "email-verification-sent") {
        return createToast({ type: "error", message: response.payload });
      }

      if (response.status === "email-verification-sent") {
        return createToast({ type: "success", message: response.payload });
      }
    }
  }, [response]);

  const handleNextStep = async () => {
    await authenticate({
      ...baseAuthenticationProps,
      action: "login",
    });
  };

  useEffect(() => {
    if (atomCredentials.email && atomCredentials.password) {
      setUserCredentials(atomCredentials);
      handleNextStep();
    }
  }, []);
  // Page Document Title
  useDocumentTitle(i18next.t("auth.login.headline"));

  return (
    <UnAuthorizedLayout>
      <div className="authentication__signup">
        <div className="signup">
          <AuthCard title={i18next.t("auth.login.headline")} type="login">
            {/* Email */}

            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.username.label")}
                textType="text"
                value={userCredentials?.email}
                placeholder={i18next.t("auth.input.username.placeholder")}
                setObjectState={setFieldValue}
                setObjectStateTargetName="email"
                functionOnEnter={async () => {
                  if (
                    userCredentials?.email &&
                    userCredentials.password &&
                    !googleAuthLoading
                  ) {
                    handleNextStep();
                  }
                }}
                error={undefined}
              />
            </div>

            {/* Password */}
            <div className="input">
              <BaseTextInput
                label={i18next.t("auth.input.password.label")}
                textType="password"
                value={userCredentials?.password}
                placeholder={i18next.t("auth.input.password.placeholder")}
                setObjectState={setFieldValue}
                setObjectStateTargetName="password"
                error={undefined}
                functionOnEnter={async () => {
                  if (userCredentials.password && !googleAuthLoading) {
                    handleNextStep();
                  }
                }}
              />
            </div>

            <div className="signup__actions">
              <Button
                disabled={!userCredentials.password || googleAuthLoading}
                variant={
                  !userCredentials.password || googleAuthLoading
                    ? "secondary"
                    : "primary"
                }
                onClickAction={async (e) => {
                  handleNextStep();
                }}
              >
                {_loading ? <Spinner /> : i18next.t("auth.input.button.login")}
                {/* {_loading ? <Spinner /> : <Spinner />} */}
              </Button>

              {(brand.hasAuth.bnet ||
                brand.hasAuth.google ||
                brand.hasAuth.steam) && (
                <>
                  <div>
                    <p>{i18next.t("auth.authcard.or")}</p>
                  </div>

                  <FirebaseAuthMethods email={userCredentials.email} />
                </>
              )}
              <p>
                <small>
                  {i18next.t("auth.login.forgot_password")}{" "}
                  <span onClick={() => navigate("/forgot-password")}>
                    {i18next.t("auth.input.button.reset")}
                  </span>
                </small>
              </p>
            </div>
          </AuthCard>
        </div>
      </div>
    </UnAuthorizedLayout>
  );
};
