import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import TRANSLATIONS_DAN from "./Danish";
import TRANSLATIONS_EN from "./English";
import TRANSLATIONS_FR from "./French";
import TRANSLATIONS_DE from "./German";
import TRANSLATIONS_IT from "./Italian";
import TRANSLATIONS_ES from "./Spanish";
import TRANSLATIONS_PL from "./Polish";
import TRANSLATIONS_NO from "./Norwegian";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "eng",
    resources: {
      eng: {
        translation: TRANSLATIONS_EN,
      },
      dan: {
        translation: TRANSLATIONS_DAN,
      },
      fr: {
        translation: TRANSLATIONS_FR,
      },
      de: {
        translation: TRANSLATIONS_DE,
      },
      it: {
        translation: TRANSLATIONS_IT,
      },
      es: {
        translation: TRANSLATIONS_ES,
      },
      pl: {
        translation: TRANSLATIONS_PL,
      },
      no: {
        translation: TRANSLATIONS_NO,
      },
    },
  });
export default i18n;
